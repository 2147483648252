import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import AOS from "aos";
import "aos/dist/aos.css";

export const axiosConstant = {
  GOOGLE_PLACES_API_KEY: "YOUR_GOOGLE_PLACES_API_KEY",
};

function EditCity() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cityTitle, setCityTitle] = useState("");
  const [cityTitleError, setCityTitleError] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [toaster, setToaster] = useState({ visibility: "hide" });

  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const cityRef = db.collection("Cities").doc(id);
        const cityDoc = await cityRef.get();

        if (cityDoc.exists) {
          const cityData = cityDoc.data();
          setCityTitle(cityData.city_name);
          setIsActive(cityData.status === 1);
        }
        AOS.init();
        AOS.refresh();
      } catch (error) {
        console.log(error);
      }
    };

    fetchCityData();
  }, [id]);

  const onSubmit = async () => {
    let isValid = true;

    if (cityTitle === "") {
      setCityTitleError("required");
      isValid = false;
    }

    if (isValid) {
      try {
        const cityRef = db.collection("Cities").doc(id);

        await cityRef.update({
          city_name: cityTitle,
          status: isActive ? 1 : 0,
          updated_at: new Date(),
        });

        setToaster({
          type: "success",
          title: "Success",
          text: "City updated successfully",
          visibility: "show",
        });

        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);

        navigate("/cityList");
      } catch (error) {
        console.log(error);
        setToaster({
          type: "data failed",
          title: "Error Occurred",
          text: "Unsuccessful",
          visibility: "show",
        });

        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);
      }
    }
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row my-md-auto align-items-center">
                  <div className="col-lg-3">
                    <h4 className="fw-bold pt-3">Edit City</h4>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="container" data-aos="fade-up">
                          <div className="row align-items-end">
                            <div className="col-md-4 mt-4">
                              <label className="form-label">City Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={cityTitle}
                                onChange={(e) => {
                                  if (e.target.value !== "") {
                                    setCityTitleError("");
                                  }
                                  if (e.target.value.length <= 1) {
                                    setCityTitle(e.target.value.trim());
                                  } else {
                                    setCityTitle(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  e.key === "Enter"
                                    ? onSubmit()
                                    : setCityTitle(e.target.value);
                                }}
                              />
                              {cityTitleError ? (
                                <div>
                                  <spna style={{ color: "red" }}>Required</spna>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mt-4">
                              <label
                                className="form-label"
                                style={{ margin: "1rem" }}
                              >
                                Status
                              </label>
                              <br />
                              <div
                                style={{
                                  display: "inline-flex",
                                  marginLeft: "5px",
                                }}
                              >
                                <div className="col-md-8 col-sm-6 col-xs-6">
                                  <input
                                    type="radio"
                                    name="cstatus"
                                    value="1"
                                    checked={isActive}
                                    onChange={() => setIsActive(true)}
                                  />
                                  Active
                                </div>
                                <div className="col-md-8 col-sm-6 col-xs-6">
                                  <input
                                    type="radio"
                                    name="cstatus"
                                    value="0"
                                    checked={!isActive}
                                    onChange={() => setIsActive(false)}
                                  />
                                  Inactive
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mt-4">
                              <button
                                className="btn  me-2 handleBtn"
                                onClick={onSubmit}
                              >
                                Save Data
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCity;
