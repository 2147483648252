import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";


function NotificationList() {
  const [notificationList, setNotificationList] = useState();
  const [isLoaded, setIsLoaded] = useState(false); // eslint-disable-next-line
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [user, setUser] = useState();


  useEffect(() => {
    getNotification();
  }, []);

 
  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Last Login",
      selector: (row) => row.login,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
        name: "Sand Message",
        selector: (row) => row.message,
        sortable: true,
      },
  

  ];

  const getNotification = async () => {
    try {
      const notificationSnapshot = db.collection("notification").get();
      const categorySnapshot = db.collection("users").get();
  
      const [notificationQuerySnapshot, categoryQuerySnapshot] = await Promise.all([
        notificationSnapshot,
        categorySnapshot,
      ]);
  
      const notificationData = notificationQuerySnapshot.docs.map(doc => doc.data());
      const categoryData = categoryQuerySnapshot.docs.map(doc => doc.data());
  
      setNotificationList(notificationData);
      setUser(categoryData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch data",
        visibility: "show",
      });
    } finally {
      
    }
  };
  // const getNotification = async () => {
  //   setIsLoaded(true);
  //   try {
  //     const querySnapshot = await db.collection("notification").get();
  //     const data = querySnapshot.docs.map(doc => doc.data());
  //     setNotificationList(data);
  //   } catch (error) {
  //     console.error("Error getting notification list:", error);
  //     setToaster({
  //       type: "error",
  //       title: "Error Occurred",
  //       text: "Failed to fetch notification list",
  //       visibility: "show",
  //     });
  //   } finally {
  //     setIsLoaded(false);
  //   }
  // };
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                {/* button */}
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Notifications List</h4>
                  {/* <Link to="/addnotification " className="btn btn-primary" type="button">
                    Add Notification
                  </Link> */}
                </div>
                <div className="card">
                  <DataTable
                    columns={columns}
                    data={notificationList}
                    direction="ltr"
                    fixedHeader
                    // fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    persistTableHead
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                  // customStyles={customStyles}
                  />
                </div>
              </div>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default NotificationList;
