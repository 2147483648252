import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import { PlusCircleOutlined, ThunderboltTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "react-bootstrap";

function CityList() {
  const [cityList, setCityList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [showModal, setShowModal] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [toggle, setToggle] = useState(false);
  const [file, setFile] = useState(null);
  const [cityTitle, setCityTitle] = useState("");
  const [cityTitleError, setCityTitleError] = useState("");
  const [citySliderImage, setCitySliderImage] = useState("");
  const [cityTitlefielderror, setcityTitlefielderror] = useState(true);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const OnSubmit = () => {
   
    let isValid = true;

    if (cityTitle === "") {
      setCityTitleError("required");
      isValid = false;
    }

    if (isValid) {
      const id = Math.random().toString();
      try {
        db.collection("Cities")
          .doc(id)
          .set({
            id: id,
            created_at: new Date(),
            city_name: cityTitle,
            deleted: "",
            status: isActive ? 1 : 0, // 1 for active, 0 for inactive
            updated_at: new Date(),

            // created_by: "Admin",
          })
          .then(() => {
            setToaster({
              type: "success",
              title: "Successfull",
              text: "city created successfully",
              visiblity: "show",
            });

            setCitySliderImage("");
            setCityTitle("");
            // window.location.reload(false)

            setTimeout(() => {
              setToaster({
                visiblity: "hide",
              });
            }, 1500);
          });
      } catch (err) {
        console.log(err, file?.name);
        setToaster({
          type: "data failed",
          title: "Error Occured",
          text: "unsuccessful ",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
      }
    }
    getCity();
    alert("save city");
    setToggle(false);
  };

  useEffect(() => {
    getCity();
    AOS.init();
  }, []);

  const handleToggle = (id) => {
    setSelectedCityId(id);
    setShowModal(true);
  };

  const handleConfirmation = async () => {
    try {
      const cityRef = db.collection("Cities").doc(selectedCityId);
      const cityDoc = await cityRef.get();
      const cityData = cityDoc.data();
      const updatedData = {
        ...cityData,
        status: cityData.status ? 0 : 1,
      };
      await cityRef.update(updatedData);

    
      const updatedCityList = cityList.map((city) => {
        if (city.id === selectedCityId) {
          return {
            ...city,
            status: city.status ? 0 : 1,
          };
        }
        return city;
      });
      setCityList(updatedCityList);
      setShowModal(false);
    } catch (error) {
      console.error("Error toggling city status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle city status",
        visibility: "show",
      });
    }
  };

  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "City Name",
      selector: (row) => row.city_name,

      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.status ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Inactive</span>
          )}
        </div>
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`/cityDetail/${row.id}`} className="menu-link">
            <i className="menu-icon tf-icons bx bx-edit"></i>
          </Link>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`toggleSwitch_${row.id}`}
              checked={!!row.status}
              onChange={() => handleToggle(row.id)}
            />
            <label
              className="form-check-label"
              htmlFor={`toggleSwitch_${row.id}`}
            ></label>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const getCity = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("Cities").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
      setCityList(data);
    } catch (error) {
      console.error("Error getting city list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch city list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold">City List</h4>

                  <Button
                    className="handleBtn"
                    type="primary"
                    onClick={() => {
                      setcityTitlefielderror(true);
                      setToggle(!toggle);
                    }}
                  >
                    <PlusCircleOutlined
                      style={{ display: "inline-flex", marginRight: "6px" }}
                    />{" "}
                    Add City
                  </Button>
                </div>

                {toggle && (
                  <div>
                    {" "}
                    <div className="row ">
                      <div className="col-md-12">
                        <div className="card mb-4">
                          <div className="card-body">
                            <div className="container" data-aos="fade-up">
                              <div className="row align-items-end">
                                <div className="col-md-4 mt-4">
                                  <label className="form-label">
                                    City Name
                                  </label>
                                  <input
                                    type="text"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="form-control"
                                    value={cityTitle}
                                    onChange={(e) => {
                                      if (e.target.value !== "") {
                                        setCityTitleError("");
                                        setcityTitlefielderror(true);
                                      }
                                      if (e.target.value.length <= 1) {
                                        setCityTitle(e.target.value.trim());
                                      } else {
                                        setCityTitle(e.target.value);
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      setcityTitlefielderror(false);
                                      if (e.key === "Enter") {
                                        if (e.target.value !== "") {
                                          setcityTitlefielderror(true);
                                        }

                                        console.log("do validate");
                                      }

                                      // e.key === "Enter"
                                      //   ? OnSubmit()
                                      //   : setCityTitle(e.target.value);
                                    }}
                                  />
                                  {cityTitlefielderror ? (
                                    <div></div>
                                  ) : (
                                    <div>
                                      <span style={{ color: "red" }}>
                                        Required
                                      </span>
                                    </div>
                                  )}
                                </div>

                                <div className="col-md-4 mt-4">
                                  <label
                                    className="form-label"
                                    style={{ marginBottom: "19px" }}
                                  >
                                    Status
                                  </label>

                                  <div className="col-md-8 col-sm-6 col-xs-6">
                                    <input
                                      type="radio"
                                      name="cstatus"
                                      value="1"
                                      checked={isActive}
                                      onChange={() => setIsActive(true)}
                                    />
                                    <label className="radio-label">
                                      Active
                                    </label>
                                    <input
                                      type="radio"
                                      name="cstatus"
                                      value="0"
                                      checked={!isActive}
                                      onChange={() => setIsActive(false)}
                                    />
                                    <label className="radio-label">
                                      Inactive
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4 mt-4">
                                  <Button
                                    className="handleBtn"
                                    onClick={() => {
                                      if (cityTitle) {
                                        OnSubmit();
                                        setcityTitlefielderror(true);
                                      }

                                      setcityTitlefielderror(false);
                                    }}
                                    onKeyDown={(e) => {
                                      if (cityTitle) {
                                        if (e.key === "Enter") {
                                          OnSubmit();
                                        }
                                      }
                                    }}

                                    // disabled={!latitude || !longitude || !citySliderImage}
                                  >
                                    Save Data
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="card">
                  <div
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <div className="d-flex justify-content-end">
                      <div class="form-outline mt-4 me-4">
                        {/* <label class="form-label" for="form1">Search</label> */}
                        <input
                          style={{ marginBottom: "-38px" }}
                          type="search"
                          id="form1"
                          class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="m-2 p-lg-0">
                      <small>Cities</small>
                    </div>
                    <hr />
                  </div>
                  <div className="card-body">
                    {isLoaded ? (
                      <Spinner />
                    ) : (
                      <DataTable
                        style={{ fontWeight: "bold" }}
                        columns={columns}
                        data={cityList.filter((row) =>
                          row.city_name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )}
                        direction="ltr"
                        fixedHeader
                        highlightOnHover
                        pagination
                        persistTableHead
                        responsive
                        subHeaderAlign="left"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to toggle the status?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CityList;
