// import services from "../../services";
import React, { useEffect } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import { useState } from "react";
import Toaster from "../../components/toaster";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { db } from "../../firebase";

import { Form } from "react-bootstrap";

export const axiosConstant = {
  GOOGLE_PLACES_API_KEY: "AIzaSyCkQqWwnxEPIrUswczekreqJehDjI1pdlQ",
};

function Period() {
  const [period, setPeriod] = useState("");

  const [products, setproduct] = useState("");
  const [title, settitle] = useState("");
  const [toaster, setToaster] = useState({ visiblity: "hide" });

  const [id, setid] = useState("");
  const [plan, setplan] = useState([]);
  const [toggle, setToggle] = useState(false);

  //getpla
  const getplan = async () => {
    try {
      const querySnapshot = await db.collection("free_trial plan").get();
      const data = querySnapshot.docs.map((doc) => {
        const categoryData = doc.data();
        // categoryData.id = doc.id;
        return categoryData;
      });
   //   console.log("lffflk", data[0].id);
      setplan(data);
      setid(data[0].id);
      // setProductsArray((homeSlider => homeSlider.filter((_, index) => index !== 0)));
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  //update data in firebase store
  const onSubmit = async () => {
    let isValid = true;

    if (isValid) {
      try {
        const cityRef = db.collection("free_trial plan").doc('1');
        let HomesliderDoc = await cityRef.get();
        let  HomesliderData = HomesliderDoc.data();
        if(!HomesliderData){
         
          db.collection("free_trial plan")
          .doc('1')
          .set({
            plan_name: title,
          products: products,
          period: period,

          updated_at: new Date(),
        })}

        await cityRef.update({
          plan_name: title,
          products: products,
          period: period,

          updated_at: new Date(),
        });
        console.log('suoer')

        setToaster({
          type: "success",
          title: "Success",
          text: "City updated successfully",
          visibility: "show",
        });

        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1000);
      } catch (error) {
        console.log(error);
        setToaster({
          type: "data failed",
          title: "Error Occurred",
          text: "Unsuccessful",
          visibility: "show",
        });

        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);
      }
    }
     getplan();
  };

 // console.log(plan);

  useEffect(() => {
   getplan();
  }, []);

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />

          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row my-md-auto align-items-center">
                  <div className="col-lg-3">
                    <h4 className="fw-bold pt-3">Free Trial Paln</h4>
                  </div>
                </div>
                <Button
                  className="handleBtn"
                  type="primary"
                  onClick={() => {
                    setToggle(!toggle);
                    settitle("");
                    setPeriod("");
                    setproduct("");
                  }}
                >
                  <PlusCircleOutlined
                    style={{ display: "inline-flex", marginRight: "6px" }}
                  />
                  Upadte Free Trial Plan
                </Button>
                {/* adddddddddddd */}
                {toggle && (
                  <div>
                  <br/>
                    <label className="form-label">paln name :</label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      className="form-control"
                      value={title}
                      onChange={(e) => {
                        // if (e.target.value !== "") {

                        //         }

                        // console.log("hey this side mo\sam", e.target.value.length);
                        if (e.target.value.length <= 1) {
                          settitle(e.target.value.trim());
                        } else {
                          settitle(e.target.value);
                        }
                      }}
                    />
  <br/>
                    <label className="form-label"> NO.of products :</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      className="form-control"
                      value={products}
                      onChange={(e) => setproduct(e.target.value)}
                    />
                    <br/>

                    <label className="form-label">period :</label>

                    <Form.Select
                      value={period || ""}
                      onChange={(e) => {
                        if (e.target.value) {
                          setPeriod(e.target.value);
                        }
                      }}
                    >
                      <option value={""}>Open this select menu</option>
                      <option value="week">week</option>
                      <option value="month">month</option>
                      <option value="year">year</option>
                    </Form.Select>

                    <Button
                      style={{ marginTop: 5 }}
                      className="handleBtn"
                      onClick={() => {
                        if (title && products && period) {
                          onSubmit();
                          setToggle(false);
                        } else {
                          message.success("All Fields Required");
                        }
                      }}
                    >
                      Update
                    </Button>
                  </div>
                )}
                <div>
                  <div className="row">
                    {plan.map((e) => (
                      <div className="col-md-2" id="col-md-2">
                        <div className="card" id="card">
                          <div className="card-body" id="card-body">
                            <h4 className="card-title">{e.plan_name} </h4>
                            <p className="card-text">{e.period}</p>
                            <p className="card-text">for fortnight</p>

                            <p className="card-text">
                              {" "}
                              No.of product:{e.products}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Period;
