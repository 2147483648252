import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";

import { Button } from "antd";

import { ArrowDownOutlined } from "@ant-design/icons";
export default function Producdetils() {
  const { id } = useParams();
  const [newvendor, setnewvendor] = useState([]);
  const [toggle, setToggle] = useState(true);

  const detailsvendor = async () => {
    console.log("newdetailsid11111111111111", id);
    if (id) {
      try {
        const vendorRef = db.collection("product").doc(id);
        const snapshot = await vendorRef.get();
        if (snapshot.exists) {
          const vendor = snapshot.data();

          setnewvendor(vendor);

          console.log("llllllllllllllllll", vendor);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    detailsvendor();
  }, []);

  //tabel data

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px" }}
      >
        <Button
          className="handleBtn"
          onClick={() => setToggle(true)}
          type="primary"
          // style={{ marginLeft: 6 }}
        >
          {" "}
          <ArrowDownOutlined />
          Product Details
        </Button>

        <Button
          className="handleBtn"
          onClick={() => setToggle(false)}
          size="lages"
          type="primary"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          {" "}
          <ArrowDownOutlined />
          Image
        </Button>
        <Link to="/vendorProductList">
          <Button
            className="handleBtn"
            // style={{ marginLeft: 5 }}
            type="primary"
          >
            <ArrowDownOutlined /> Go To Product List
          </Button>
        </Link>
      </div>

      {toggle ? (
        <div
          style={{
            width: "60%",
            margin: "auto",
            border: "2px solid #c7c7c7",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          <div>
            <table class="table table-hover table-grey">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ backgroundColor: "gray", color: "white" }}
                  >
                    FIELDS
                  </th>
                  <th
                    scope="col"
                    style={{ backgroundColor: "gray", color: "white" }}
                  >
                    VALUE
                  </th>
                </tr>
              </thead>
              <tbody className="handleAddCatgory">
                <tr>
                  <th scope="row">name :</th>
                  <td>{newvendor.name}</td>
                </tr>
                <tr>
                  <th scope="row">Price :</th>
                  <td>{newvendor.price}</td>
                </tr>
                <tr>
                  <th scope="row"> Price Unit :</th>
                  <td>{newvendor.priceUnit}</td>
                </tr>
                <tr>
                  <th scope="row">Category :</th>
                  <td>{newvendor.category}</td>
                </tr>
                <tr>
                  <th scope="row"> Sub Category :</th>
                  <td>{newvendor.subcategory}</td>
                </tr>
                <tr>
                  <th scope="row"> Minimum Booking Amount :</th>
                  <td>{newvendor.minimumBookingAmount}</td>
                </tr>
                <tr>
                  <th scope="row"> User id : </th>
                  <td>{newvendor.user_id}</td>
                </tr>

                <tr>
                  <th scope="row"> IsApproved : </th>
                  <td>{newvendor.isApproved}</td>
                </tr>

                <tr>
                  <th scope="row"> Fields :</th>
                  <td>
                    {newvendor.fields &&
                      newvendor.fields.map((item) => (
                        <div>
                          <p>Key:{item.key}</p>
                          <p>Value{item.value}</p>
                        </div>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            {newvendor &&
              newvendor?.image?.length > 0 &&
              newvendor?.image.map((item) => (
                <div className="col-md-3">
                  <div className="card" style={{ width: "157px" }}>
                    <div
                      className="card-body"
                      style={{ width: "153px", marginTop: "6px" }}
                    >
                      <img
                        style={{ marginLeft: "5px", marginTop: "-13px" }}
                        id="img"
                        src={item}
                        alt="Good Morning Friends"
                        width={100}
                        height={150}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
