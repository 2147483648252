import React from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import { useState, useEffect } from "react";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import AOS from "aos";
import "aos/dist/aos.css";

export const axiosConstant = {
  GOOGLE_PLACES_API_KEY: "YOUR_API_KEY",
};

function AddSubCategory() {
  let navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [subCategorytitle, setSubcategorytitle] = useState("");
  const [subCategorytitleError, setSubcategorytitleError] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [url, setUrl] = useState("");
  const [inputList, setInputList] = useState([{ label: "" }]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(`ListingImages/${file.name}`)
        .put(file); // eslint-disable-next-line no-restricted-globals
      let conf = confirm("Upload");
      console.log(conf, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.log("Error uploading file:", error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              setUrl(downloadURL);
              console.log(
                "File uploaded successfully. Download URL:",
                downloadURL
              );
            })
            .catch((error) => {
              console.log("Error getting download URL:", error);
            });
        }
      );
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { label: "" }]);
  };
  // const handleIconChange = (e) => {

  //   setFile(e.target.files[0]);
  //   console.log(e.target.files[0], "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
  // };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const querySnapshot = await db.collection("category").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
      console.log("Category data:", data);
      setSelectedCategory(data);
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  const onSubmit = () => {
    let isValid = true;

    if (subCategorytitle === "") {
      setSubcategorytitleError("required");
      isValid = false;
    }

    if (isValid) {
      const id = Math.random().toString();
      try {
        db.collection("subCategory")
          .doc(id)
          .set({
            id: id,
            name: subCategorytitle,
            image: url,
            category_id: category,
            form_field: inputList,
            active: isActive,
            products: [],
          })
          .then(() => {
            setToaster({
              type: "success",
              title: "Successful",
              text: "Category created successfully",
              visibility: "show",
            });

            setSubcategorytitle("");
            setUrl("");

            setTimeout(() => {
              setToaster({
                visibility: "hide",
              });
            }, 1500);
          });
        navigate("/subCategoryList");
      } catch (err) {
        console.log("Error creating subcategory:", err);
        setToaster({
          type: "error",
          title: "Error Occurred",
          text: "Failed to create subcategory",
          visibility: "show",
        });
        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);
      }
    }
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row my-md-auto align-items-center">
                  <div className="col-lg-3">
                    <h4 className="fw-bold pt-3">Add Category</h4>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4 card-header">
                      <div className="card-body card-header">
                        <div className="row align-items-end">
                          {/* 
                          <div className="col-md-4 mt-4">
                            <label className="form-label">Select Category</label>
                            <select
                              className="col-md-12 p-2"
                              value={category || ""}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option value="">Select a category</option>
                              {selectedCategory &&
                                selectedCategory.map((cat) => (
                                  <option key={cat.name} value={cat.name}>
                                    {cat.name}
                                  </option>
                                ))}
                            </select>
                          </div> */}
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="exampleFormControlSelect1"
                              className="form-label"
                            >
                              Select Category
                            </label>
                            {
                              <select
                                className="form-select"
                                id="exampleFormControlSelect1"
                                aria-label="Default select example"
                                value={category || ""}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value={""}>Select category</option>
                                {selectedCategory.map((cat) => {
                                  return (
                                    <option key={cat.name} value={cat.name}>
                                      {cat.name}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          </div>

                          {/* <div className="col-md-4">
                            <label>Sub Category Title</label>
                            <input
                              type="text"
                              className="form-control"
                              value={subCategorytitle}
                              onChange={(e) => {
                                setSubcategorytitle(e.target.value);
                                setSubcategorytitleError("");
                              }}
                            />
                            {subCategorytitleError && (
                              <div className="text-danger">{subCategorytitleError}</div>
                            )}
                          </div> */}
                          <div className="col-md-4 mt-4">
                            <label className="form-label">
                              Sub Category Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={subCategorytitle}
                              onChange={(e) => {
                                setSubcategorytitle(e.target.value);
                                setSubcategorytitleError("");
                              }}
                            />
                            {subCategorytitleError && (
                              <div className="position-absolute alert-danger mt-2">
                                {subCategorytitleError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label>Image</label>
                            <input
                              type="file"
                              onChange={handleImageChange}
                              accept="image/gif, image/jpeg"
                            />
                            {file && (
                              <div>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt="Selected"
                                />
                                <button onClick={handleUpload}>Upload</button>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label className="form-label">Status</label>
                            <div className="col-md-8 col-sm-6 col-xs-6">
                              <input
                                type="radio"
                                name="cstatus"
                                value="1"
                                checked={isActive}
                                onChange={() => setIsActive(true)}
                              />
                              <label className="radio-label">Active</label>
                              <input
                                type="radio"
                                name="cstatus"
                                value="0"
                                checked={!isActive}
                                onChange={() => setIsActive(false)}
                              />
                              <label className="radio-label">Inactive</label>
                            </div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <button
                              type=""
                              className="btn btn-primary"
                              onClick={onSubmit}
                            >
                              Save Data
                            </button>
                          </div>
                          <div className="col-md-4 mt-4">
                            <label className="form-label handleSubCategory">
                              Sub Category Form Fields
                            </label>

                            {inputList.map((x, i) => {
                              return (
                                <div className="box">
                                  <input
                                    name="label"
                                    required
                                    placeholder="Enter Field Label"
                                    value={x.inputList}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                  <div className="btn-box">
                                    {inputList.length !== 1 && (
                                      <button
                                        className=""
                                        onClick={() => handleRemoveClick(i)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                    {inputList.length - 1 === i && (
                                      <button onClick={handleAddClick}>
                                        Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubCategory;
