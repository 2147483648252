import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import AOS from "aos";
import "aos/dist/aos.css";
import firebase from "firebase/compat/app";
import { Button, message } from "antd";
function CategoryDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryTitleError, setCategoryTitleError] = useState("");
  const [categoryImage, setCategoryImage] = useState();
  const [file, setFile] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [url, setUrl] = useState("");
  const [checkimagepath, setimagepath] = useState(false);
  const [updateImage, setUpdateImage] = useState(true);
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const categoryRef = db.collection("category").doc(id);
        const categoryDoc = await categoryRef.get();

        if (categoryDoc.exists) {
          const categoryData = categoryDoc.data();
          setCategoryTitle(categoryData.name);
          setIsActive(categoryData.active);
          setCategoryImage(categoryData.image);
        }
        AOS.init();
        AOS.refresh();
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategoryData();
  }, [id]);

  
 // console.log("file", file, "url", url);
  const onSubmit = async () => {
    let isValid = true;

    if (categoryTitle === "") {
      setCategoryTitleError("required");
      isValid = false;
    }

    if (isValid) {
      try {
        const categoryRef = db.collection("category").doc(id);
        if (url) {
          await categoryRef.update({
            name: categoryTitle,
            image: url,
            active: isActive,
            updated_at: new Date(),
          });
          console.log("fffffff", url);
        } else {
          await categoryRef.update({
            name: categoryTitle,
            image: categoryImage,
            active: isActive,
            updated_at: new Date(),
          });
   //       console.log("cccccccccccccccc", categoryImage);
        }

        setToaster({
          type: "success",
          title: "Success",
          text: "Category updated successfully",
          visibility: "show",
        });

        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);

        navigate("/categoryList");
      } catch (error) {
        console.log(error);
        setToaster({
          type: "data failed",
          title: "Error Occurred",
          text: "Unsuccessful",
          visibility: "show",
        });

        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);
      }
    }
  };
  console.log(url, "hhhhhhhhhhh", file);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = () => {
    if (file) {
      setimagepath(true);
      setUpdateImage(false);
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(`ListingImages/${file.name}`)
        .put(file); // eslint-disable-next-line no-restricted-globals
      // let conf = confirm("Upload")
      // console.log(conf, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.log("Error uploading file:", error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              setUrl(downloadURL);
              console.log(
                "File uploaded successfully. Download URL:",
                downloadURL
              );
            })
            .catch((error) => {
              console.log("Error getting download URL:", error);
            });
        }
      );
    }
    message.success("Image Upload Sucessfully");
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row my-md-auto align-items-center">
                  <div className="col-lg-3">
                    <h4 className="fw-bold pt-3">Edit Category</h4>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4 card-header">
                      <div className="card-body card-header">
                        <div className="row align-items-end">
                          <div className="col-md-4 mt-4">
                            <label className="form-label">Category Title</label>
                            <input
                              type="text"
                              className="form-control"
                              value={categoryTitle}
                              onChange={(e) => {
                                if (e.target.value !== "") {
                                  setCategoryTitleError("");
                                }
                                if (e.target.value.length <= 1) {
                                  setCategoryTitle(e.target.value.trim());
                                } else {
                                  setCategoryTitle(e.target.value);
                                }
                              }}
                            />
                            {categoryTitleError ? (
                              <div>
                                <span style={{ color: "red" }}>Required</span>
                              </div>
                            ) : null}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label">Category Icon</label>
                            <br />
                            <input
                              type="file"
                              onChange={handleFileChange}
                              accept="image/gif, image/jpeg, image/png"
                              style={{
                                border: " 1px solid #337ab7",
                                width: "13rem",
                              }}
                            />

                            {file && (
                              <div>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt="Selected"
                                  style={{width:"100px",height:"100px",marginTop:"3px"}}
                                />
                                <br />
                                {updateImage && (
                                  <Button
                                    className="handleBtn"
                                    type="primary"
                                    style={{ marginTop: "6px" }}
                                    onClick={() => {
                                      handleUpload();
                                      // setimagerequred(true)
                                    }}
                                  >
                                    Upload
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label">Status</label>
                            <div className="col-md-8 col-sm-6 col-xs-6">
                              <input
                                type="radio"
                                name="cstatus"
                                value="1"
                                checked={isActive}
                                onChange={() => setIsActive(true)}
                              />
                              <label className="radio-label">Active</label>
                              <input
                                type="radio"
                                name="cstatus"
                                value="0"
                                checked={!isActive}
                                onChange={() => setIsActive(false)}
                              />
                              <label className="radio-label">Inactive</label>
                            </div>
                          </div>

                          <div className="col-md-4 mt-4">
                            <button
                              type="button"
                              className="btn  me-2 handleBtn"
                              onClick={onSubmit}
                            >
                              Save Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryDetail;
