import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import moment from 'moment';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Modal, Button } from 'react-bootstrap';

function VendorBookingList() {
  const [vendorBookingList, setVendorBookingList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [showModal, setShowModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    getBooking();
    AOS.init();
  }, []);

  const handleToggle = (id, bookingStatus) => {
    setSelectedBookingId(id);
    setShowModal(true);
    // getBooking();
  };

  const handleConfirmation = async () => {
    try {
      const bookingRef = db.collection("Booking").doc(selectedBookingId);
      const bookingDoc = await bookingRef.get();
      const bookingData = bookingDoc.data();
      const updatedData = {
        booking_status: bookingData.booking_status === 0 ? 1 : 0,
      };
      await bookingRef.update(updatedData);

      const updatedBookingList = vendorBookingList.map((booking) => {
        if (booking.booking_id === selectedBookingId) {
          return {
            ...booking,
            booking_status: bookingData.booking_status === 0 ? 1 : 0,
          };
        }
        return booking;
      });
      setVendorBookingList(updatedBookingList);
      setShowModal(false);

    } catch (error) {
      console.error("Error toggling booking status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle booking status",
        visibility: "show",
      });
    }
  };
  const handleCancel = () => {
    setShowModal(false);
  };
  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.name1,
      sortable: true,
    },
    {
      name: "Booking Amount",
      selector: (row) => row.payable_amount,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.date_from).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.date_to).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Customer Contact",
      selector: (row) => row.owner_user_id,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.booking_status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.booking_status === 0 ? (
            <span className="badge bg-danger">Rejected</span>
          ) : (
            <span className="badge bg-success">Approved</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`toggleSwitch_${row.booking_id}`}
              onChange={() => handleToggle(row.booking_id, row.booking_status)}
              checked={!!row.booking_status}
            />
            <label className="form-check-label" htmlFor={`toggleSwitch_${row.id}`}></label>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
    // {
    //   name: 'Action',
    //   cell: (row) => (
    //     <>

    //       <div className="form-check form-switch">
    //       <input
    //           className="form-check-input"
    //           type="checkbox"
    //           id={`toggleSwitch_${row.booking_id}`}
    //           defaultChecked={!!row.booking_status}
    //           onChange={() => handleToggle(row.booking_id, row.booking_status)}
    //         />
    //         <label className="form-check-label" htmlFor={`toggleSwitch_${row.id}`}></label>
    //       </div>
    //     </>
    //   ),
    //   allowOverflow: true,
    //   button: true,
    //   width: '56px',
    // },
  ];

  const getBooking = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("Booking").get();
      const data = [];
      for (var i = 0; i < querySnapshot.docs.length; i++) {
        const querySnapshot1 = await db.collection("product").doc(querySnapshot.docs[i].get('product_id')).get();
        const name = querySnapshot1.get("name");
        const d = querySnapshot.docs[i].data();
        d['name1'] = name;
        data.push(d);
      }
      setVendorBookingList(data);
    } catch (error) {
      console.error("Error getting booking list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch booking list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold">Vendor Booking List</h4>
                </div>
                <div className="card">
                  <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className="d-flex justify-content-end">

                      <div class="form-outline mt-4 me-4">
                        {/* <label class="form-label" for="form1">Search</label> */}
                        <input type="search" id="form1" class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch} />

                      </div>
                    </div>
                    <div className="m-2 p-lg-0">

                      <small>Vendor booking</small>
                    </div>
                    <hr />
                  </div>
                  <div className="card-body">

                    <DataTable
                      columns={columns}
                      data={vendorBookingList.filter((row) =>
                        row.name1 && row.name1.toLowerCase().includes(searchText.toLowerCase())
                      )}
                      direction="ltr"
                      fixedHeader
                      highlightOnHover
                      pagination
                      persistTableHead
                      responsive
                      subHeaderAlign="left"
                    />
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header >
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VendorBookingList;
