import Header from "../../components/header";
import { useState } from "react";
// import services from "../../services";
import SideBar from "../../components/sideBar";
import locales from "../../locales/en.json";

function Profile() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  // useEffect(()=> {
  //   let userDetails = JSON.parse(localStorage.getItem('userDetails'))
  //   setEmail(userDetails.email)
  //   setName(userDetails.name)
  // })
  const updateProfile = async () => {
    let data = {
      name: name,
      // email: email,
      password: password,
    }
    // try {
    //   const response = await services.auth.UPDATE_USER(data)
    //   console.log(response)
    // } catch (error) {
    // }
  }
  return (
    <div>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">

          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">{locales.AccountSettings}/</span> {locales.Account}</h4>

                <div className="row">
                  <div className="col-md-12">

                    <div className="card mb-4">
                      <h5 className="card-header">{locales.ProfileDetails}</h5>

                      <div className="card-body">
                        <div className="d-flex align-items-start align-items-sm-center gap-4">
                          <img
                            src="../assets/img/avatars/1.png"
                            alt="profile"
                            className="d-block rounded"
                            height="100"
                            width="100"
                            id="uploadedAvatar"
                          />
                          <div className="button-wrapper">
                            <label for="upload" className="btn btn-primary me-2 mb-4" tabindex="0">
                              <span className="d-none d-sm-block">{locales.Uploadnewphoto}</span>
                              <i className="bx bx-upload d-block d-sm-none"></i>
                              <input
                                type="file"
                                id="upload"
                                className="account-file-input"
                                hidden
                                accept="image/png, image/jpeg"
                              />
                            </label>
                            <button type="button" className="btn btn-outline-secondary account-image-reset mb-4">
                              <i className="bx bx-reset d-block d-sm-none"></i>
                              <span className="d-none d-sm-block">{locales.Reset}</span>
                            </button>

                            <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                          </div>
                        </div>
                      </div>
                      <hr className="my-0" />
                      <div className="card-body">

                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label for="firstName" className="form-label">{locales.FirstName}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="firstName"
                              name="firstName"
                              value={name}
                              autofocus
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="lastName" className="form-label">{locales.lastName}</label>
                            <input
                              value={lastName}

                              onChange={(e) => setLastName(e.target.value)} className="form-control" type="text" name="lastName" id="lastName" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="email" className="form-label">{locales.Email}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="email"
                              name="email"



                              placeholder="john.doe@example.com"
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="password" className="form-label">{locales.password}</label>
                            <input
                              className="form-control"
                              type="password"
                              id="password"
                              name="password"

                              value={password}

                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="***"
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="organization" className="form-label">{locales.Designation}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="organization"
                              name="organization"
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="organization" className="form-label">{locales.Team}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="organization"
                              name="organization"
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="organization" className="form-label">{locales.Function}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="organization"
                              name="organization"
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label className="form-label" for="phoneNumber">{locales.PhoneNumber}</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text">US (+1)</span>
                              <input
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                className="form-control"
                                placeholder="202 555 0111"
                              />
                            </div>
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="address" className="form-label">{locales.Address}</label>
                            <input type="text" className="form-control" id="address" name="address" placeholder="Address" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="state" className="form-label">State</label>
                            <input className="form-control" type="text" id="state" name="state" placeholder="California" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="zipCode" className="form-label">{locales.ZipCode}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipCode"
                              name="zipCode"
                              placeholder="231465"
                              maxlength="6"
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <button type="submit" onClick={() => updateProfile()} className="btn btn-primary me-2">Save changes</button>
                          {/* <button type="reset" className="btn btn-outline-secondary">Cancel</button> */}
                        </div>

                      </div>

                    </div>
                    <div className="card">
                      <h5 className="card-header">{locales.DeleteAccount}</h5>
                      <div className="card-body">
                        <div className="mb-3 col-12 mb-0">
                          <div className="alert alert-warning">
                            <h6 className="alert-heading fw-bold mb-1">{locales.Areyousureyouwanttodeleteyouaccount}</h6>
                            <p className="mb-0">{locales.OnceyoudeleteyouraccountthereisnogoingbackPleasebecertain}</p>
                          </div>
                        </div>
                        <form id="formAccountDeactivation" onsubmit="return false">
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="accountActivation"
                              id="accountActivation"
                            />
                            <label className="form-check-label" for="accountActivation"
                            >{locales.Iconfirmmyaccountdeactivation}</label
                            >
                          </div>
                          <button type="submit" className="btn btn-danger deactivate-account">{locales.DeactivateAccount}</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
export default Profile;