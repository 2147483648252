// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // BASE_URL: 'http://localhost:3300/v1',
  BASE_URL: 'http://18.195.40.168:3000/v1',
  REGION: 'eu-central-1',
  S3_BUCKET: 'maypi-media',
  ACCESS_KEYID: "AKIAZBRDIAFA7DNRMNMQ",
  SECRET_ACCESS_KEY: "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",
  // S3_BUCKET_IMAGE: 'ot-dev-media-convert-destination920a3c57-aljc201n45zh'
}


