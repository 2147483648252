import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";


function ComplaintList() {
  const [complaintList, setComplaintList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); // eslint-disable-next-line
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    getComplaint();
  }, []);

  const handleToggle = async (id) => {
    try {
      const complaintRef = db.collection("product").doc(id);
      
      const complaintDoc = await complaintRef.get();
      const complaintData = complaintDoc.data();
      
      const updatedData = {
        ...complaintData,
        Status: !complaintData.Status,
      };
      await complaintRef.update(updatedData);
      
      const updatedComplaintList = complaintList.map((complaint) => {
        if (complaint.id === id) {
          return {
            ...complaint,
            Status: !complaint.Status,
          };
        }
        getComplaint();
        return complaint;
      });
      setComplaintList(updatedComplaintList);
    } catch (error) {
      console.error("Error toggling complaint status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle complaint status",
        visibility: "show",
      });
    }
  };
  
  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "User Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "User Contact",
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
        name: "Complaints",
        selector: (row) => row.user_name,
        sortable: true,
      },
      
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => (
          <div>
            {row.status ? (
              <span className="badge bg-success">Approved</span>
            ) : (
              <span className="badge bg-danger">Pending</span>
            )}
          </div>
        ),
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            {/* <Link to={`/complaintDetail/${row.id}`} className="menu-link">
              <i className="menu-icon tf-icons bx bx-edit"></i>
            </Link> */}
      
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id={`toggleSwitch_${row.id}`}
                defaultChecked={!!row.Status}
                onChange={() => handleToggle(row.id)}
              />
              <label className="form-check-label" htmlFor={`toggleSwitch_${row.id}`}>
              </label>
            </div>
          </>
        ),
        allowOverflow: true,
        button: true,
        width: "56px",
      }

  ];

  const getComplaint = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("complaint").get();
      const data = querySnapshot.docs.map(doc => doc.data());
      setComplaintList(data);
    } catch (error) {
      console.error("Error getting complaint list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch complaint list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                {/* button */}
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Complaints List</h4>
                  {/* <Link to="/addcomplaint " className="btn btn-primary" type="button">
                    Add Complaint
                  </Link> */}
                </div>
                <div className="card">
                <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className="d-flex justify-content-end">

                      <div class="form-outline mt-4 me-4">
                        {/* <label class="form-label" for="form1">Search</label> */}
                        <input type="search" id="form1" class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch} />

                      </div>
                    </div>
                    <div className="m-2 p-lg-0">

                   <small>Vendor Product</small> 
                    </div>
                      <hr />
                  </div>
                  <DataTable
                        columns={columns}
                        data={complaintList.filter((row) =>
                          row.name.toLowerCase().includes(searchText.toLowerCase())
                        )}
                    direction="ltr"
                    fixedHeader
                    // fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    persistTableHead
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                  // customStyles={customStyles}
                  />
                </div>
              </div>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default ComplaintList;
