import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "react-bootstrap";
import firebase from "firebase/compat/app";
import { message, Steps, theme, Button } from "antd";
import "../Products/vendortbale.css";
import Form from "react-bootstrap/Form";

import {
  MinusCircleFilled,
  PlusCircleTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import { values } from "lodash";
function SubCategoryList() {
  const [subCategoryList, setSubCategoryList] = useState([]);
  // const [subCategoryList, setSubCategoryList] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });

  const [showModal, setShowModal] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [categoryNames, setCategoryNames] = useState({});
  const [searchText, setSearchText] = useState("");
  const [toggle, setToggle] = useState(false);

  const [file, setFile] = useState(null);
  const [subCategorytitle, setSubcategorytitle] = useState("");
  const [subCategorytitleError, setSubcategorytitleError] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [newform, setform] = useState(false);
  const [titleRequired, settitleRequired] = useState(true);
  const [url, setUrl] = useState("");
  const [inputList, setInputList] = useState([{ label: "" }]);
  const [input, setInput] = useState();
  const [imageRequired, setimagerequred] = useState(true);
  const [update, setupdate] = useState(true);
  const [selecterequired, setselectRequired] = useState(true);
  const [check, setCheck] = useState(false);
  const [checkimagepath, setimagepath] = useState(false);
  const [functioncall, setFuntioncall] = useState();
  // const[updateImage,setUpdateImage]=useState(true)

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setimagerequred(true);
    }
  };

  const handleUpload = () => {
    if (file) {
      setimagepath(true);
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(`ListingImages/${file.name}`)
        .put(file); // eslint-disable-next-line no-restricted-globals
      // let conf = confirm("Upload")
      // console.log(conf, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.log("Error uploading file:", error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              setUrl(downloadURL);
              console.log(
                "File uploaded successfully. Download URL:",
                downloadURL
              );
            })
            .catch((error) => {
              console.log("Error getting download URL:", error);
            });
        }
      );
    }
    message.success("Image Upload Successfully");
    setupdate(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setInput(true);
  };
  const handleAddClick = () => {
    setInput(false);
    setInputList([...inputList, { label: "" }]);
  };
  // const handleIconChange = (e) => {

  //   setFile(e.target.files[0]);
  //   console.log(e.target.files[0], "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
  // };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const querySnapshot = await db.collection("category").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
      setSelectedCategory(data);
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  const onSubmit = () => {
    let isValid = true;

    if (subCategorytitle === "") {
      setSubcategorytitleError("required");
      isValid = false;
    }

    if (isValid) {
      const id = Math.random().toString();
      try {
        db.collection("subCategory")
          .doc(id)
          .set({
            id: id,
            name: subCategorytitle,
            image: url,
            category_id: category,
            form_field: inputList,
            active: isActive,
            products: [],
          })
          .then(() => {
            setToaster({
              type: "success",
              title: "Successful",
              text: "Category created successfully",
              visibility: "show",
            });

            setSubcategorytitle("");
            setUrl("");

            setTimeout(() => {
              setToaster({
                visibility: "hide",
              });
            }, 1500);
          });
      } catch (err) {
        console.log("Error creating subcategory:", err);
        setToaster({
          type: "error",
          title: "Error Occurred",
          text: "Failed to create subcategory",
          visibility: "show",
        });
        setTimeout(() => {
          setToaster({
            visibility: "hide",
          });
        }, 1500);
      }
    }
    alert("Sub_Category add ");
    getSubCategory();
  };

  useEffect(() => {
    getSubCategory();
    AOS.init();
  }, []);

  const handleToggle = async (id) => {
    // Set the selected subcategory ID
    setSelectedSubCategoryId(id);
    // Show the confirmation modal
    setShowModal(true);
  };

  const handleCancel = () => {
    // Reset the selected subcategory ID and hide the modal
    setSelectedSubCategoryId(null);
    setShowModal(false);
  };

  const handleConfirmation = async () => {
    try {
      const categoryRef = db
        .collection("subCategory")
        .doc(selectedSubCategoryId);
      const categoryDoc = await categoryRef.get();
      const categoryData = categoryDoc.data();
      const updatedData = {
        ...categoryData,
        active: !categoryData.active,
      };
      await categoryRef.update(updatedData);

      const updatedSubCategoryList = subCategoryList.map((category) => {
        if (category.id === selectedSubCategoryId) {
          return {
            ...category,
            active: !category.active,
          };
        }
        return category;
      });
      setSubCategoryList(updatedSubCategoryList);
      setShowModal(false);
      getSubCategory();
    } catch (error) {
      console.error("Error toggling category status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle category status",
        visibility: "show",
      });
    }
  };

  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Sub Category Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Form Field",
      selector: (row) => row.form_field,
      sortable: true,
      cell: (row) => (
        <div>
          {Object.keys(row.form_field).map((key, index) => (
            <span key={key}>
              {row.form_field[key].label}
              {index !== Object.keys(row.form_field).length - 1 && ", "}
            </span>
          ))}
        </div>
      ),
    },

    {
      name: "Category Name",
      selector: (row) => row.category_id,
      sortable: true,
      cell: (row) => categoryNames[row.category_id] || "",
    },
    {
      name: "Icon Image",
      cell: (row) => <img src={row.image} alt="Category Icon" width="50" />,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.active,
      sortable: true,
      cell: (row) => (
        <div>
          {row.active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Inactive</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`/subCategoryDetail/${row.id}`} className="menu-link">
            <i className="menu-icon tf-icons bx bx-edit"></i>
          </Link>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`toggleSwitch_${row.id}`}
              checked={!!row.active}
              onChange={() => handleToggle(row.id)}
            />
            <label
              className="form-check-label"
              htmlFor={`toggleSwitch_${row.id}`}
            ></label>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const done=()=>{

  }  
  
  const getSubCategory = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("subCategory").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
      setSubCategoryList(data);

      // Fetch category names
      const categoryIds = data.map((subCategory) => subCategory.category_id);
      const categoryNamesMap = {};

      await Promise.all(
        categoryIds.map(async (categoryId) => {
          categoryNamesMap[categoryId] = categoryId;
          // console.log(categoryId,"h===========>>>>>>>>.....")

          // const categoryDoc = await db.collection("category").doc(categoryId).get();
          // const categoryData = categoryDoc.data();
          // categoryNamesMap[categoryId] = categoryData?.name || "";
          // console.log(categoryData?.name,"HHHHHHHHHHHHHHHHHHHHHHH")
        })
      );

      setCategoryNames(categoryNamesMap);
    } catch (error) {
      console.error("Error getting subcategory list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch subcategory list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  // add   style in form
  //next function setp

  const steps = [
    {
      title: "First",
      content: (
        <div>
          <form>
            <div class="form-row">
              <div
                class="form-group col-md-6 col-sm-12"
                style={{ float: "left",marginTop:'10px' }}
              >
                <label style={{ marginRight: "70%", color: "#000" }}>
                  Sub Category Title <sup style={{ color: "red" }}>*</sup>
                </label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Enter category"
                    value={subCategorytitle}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        setSubcategorytitleError("");
                        settitleRequired(true);
                      }

                      // console.log("hey this side mo\sam", e.target.value.length);
                      if (e.target.value.length <= 1) {
                        setSubcategorytitle(e.target.value.trim());
                      } else {
                        setSubcategorytitle(e.target.value);
                      }
                    }}
                    onKeyDown={(e) => {
                      settitleRequired(false);
                      if (e.key === "Enter") {
                        settitleRequired(true);
                        console.log("do validate");
                      }

                      e.key === "Enter"
                        ? onSubmit()
                        : setSubcategorytitle(e.target.value);
                    }}
                  />
                  {titleRequired ? (
                    ""
                  ) : (
                    <label style={{ color: "red", marginRight: "90%" }}>
                      Required
                    </label>
                  )}
                  {subCategorytitleError ? (
                    <span style={{ color: "red" }}>
                      {" "}
                      {subCategorytitleError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="form-group col-md-4 col-sm-12 " style={{marginTop:'10px'}}>
                <label
                  for="inputState"
                  style={{ marginRight: "71%", color: "#000" }}
                >
                  Select Category <sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="inputState"
                  class="form-control"
                  value={category || ""}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setselectRequired(true);
                    setCheck(e.target.value);
                    setform(true);
                  }}
                >
                  <option value={""}>Select category</option>
                  {selectedCategory.map((cat) => {
                    return (
                      <option key={cat.name} value={cat.name}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
                {selecterequired ? (
                  <div></div>
                ) : (
                  <div>
                    <span style={{ color: "red", marginRight: "85%" }}>
                      {" "}
                      Required
                    </span>
                  </div>
                )}
              </div>
            </div>

            <>
              <br />
              <div></div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  {/* <div
                    class="form-group"
                    style={{
                      display: "inline-block",
                      color: "#000",
                      marginRight: "70%",
                    }}
                  >
                    <label
                      for="exampleFormControlFile1"
                      style={{ float: "left", marginLeft: "3px" }}
                    >
                      Image <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <br />
                    <input
                      type="file"
                      class="form-control-file"
                      id="exampleFormControlFile1"
                      onChange={handleImageChange}
                      accept="image/gif, image/jpeg"
                    />
                  </div> */}
                </div>
                <div className="col-md-6">
                  {/* <div className="form-row handleradio">
                    {" "}
                    <label style={{ color: "#000", marginRight: "19px" }}>
                      Status<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="col-md-8 col-sm-6 col-xs-6">
                      <div className="hdlerado">
                        <input
                          type="radio"
                          name="cstatus"
                          value="1"
                          checked={isActive}
                          onChange={() => setIsActive(true)}
                        />
                        <label className="radio-label ">Active</label>
                        <input
                          type="radio"
                          name="cstatus"
                          value="0"
                          checked={!isActive}
                          onChange={() => setIsActive(false)}
                        />
                        <label className="radio-label">Inactive</label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </>
         
          </form>
          <div className="form-row handleradio" style={{ float: "right" }}>
              {" "}
              <label style={{ color: "#000", marginRight: "73px",marginTop:'33px' }}>
                Status<sup style={{ color: "red" }}>*</sup>
              </label>
              <div className="col-md-8 col-sm-6 col-xs-6">
                <div className="hdlerado">
                  <input
                    type="radio"
                    name="cstatus"
                    value="1"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />
                  <label className="radio-label ">Active</label>
                  <input
                    type="radio"
                    name="cstatus"
                    value="0"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />
                  <label className="radio-label">Inactive</label>
                </div>
              </div>
            </div>
          <div
            class="form-group"
            style={{
              display: "inline-block",
              color: "#000",
              marginRight: "70%",
              display: 'inline-block',
    marginTop: '2.9%'
            }}
          >
            <label for="exampleFormControlFile1" className="saLabl">
              Image <sup style={{ color: "red" }}>*</sup>
            </label>
            <br />
            <input
              type="file"
              class="form-control-file mosum"
              id="exampleFormControlFile1"
              onChange={handleImageChange}
              accept="image/gif, image/jpeg"
              style={{
                                border: " 1px solid #337ab7",
                                width: "13rem",
                              marginRight:'105px'
                              }}
            />
          </div>
          {imageRequired ? (
            <div></div>
          ) : (
            <div>
              <span
                style={{
                  color: "red",
                  position: "relative",
                  // top: "-2rem",
                  left: "-46.5%",
                }}
              >
                {" "}
                Required
              </span>
            </div>
          )}
          {file && (
            <div style={{}}>
              <div>
                <img
                  src={URL.createObjectURL(file)}
                  alt="Selected"
                   className="handleUploadImg"
                />
              </div>
              {update && (
                <div style={{}}>
                  <Button
                    className="handleBtn"
                    style={{
                      marginLeft: "-92%",
                      marginTop: "0.5rem",
                    }}
                    onClick={() => {
                      handleUpload();
                      // setimagerequred(true)
                    }}
                  >
                    Upload
                  </Button>
                </div>
              )}

          
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Second",
      content: (
        <div>
          {/* data tebale in  */}
          <div>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                fontFamily: "arial,sans-serif",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Fields:
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Values
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Field Label 1:
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  ProductsName
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Field Label 2:
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Rate
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Field Label 3:
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  {" "}
                  Minimum Booking price
                </td>
              </tr>
            </table>
          </div>
       
          <div>
            <label style={{ color: "black",marginLeft:'28.5px',marginTop:'14px' }}>Sub Category Form Fields</label>
            {/* <hr style={{ color: "black" }} /> */}

            {inputList.map((x, i) => {
              return (
                <div>
                  <label style={{ color: "black", margin: "8px" }}>
                    {" "}
                    Add Field:
                  </label>
                  <input
                    style={{ borderRadius: 6, border: "2px solid #337ab7" }}
                    name="label"
                    type="text"
                    required
                    placeholder="Enter Field Label"
                    value={x.inputList}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        handleInputChange(e, i);

                        setFuntioncall(true);
                      } else {
                        setFuntioncall(false);
                      }
                    }}
                  />

                  {inputList.length !== 1 && (
                    <label
                      className="handleLabels1"
                      onClick={() => {
                        setFuntioncall(true);
                        handleRemoveClick(i);
                      }}
                    >
                      {" "}
                      <MinusCircleFilled />
                    </label>
                  )}

                  {inputList.length - 1 === i && (
                    <label
                      className="handleLabels"
                      onClick={() => {
                        if (functioncall) {
                          handleAddClick();
                          setFuntioncall(false);
                        } else {
                          message.success("Add Fields Required");
                        }
                      }}
                    >
                      {" "}
                      <PlusCircleTwoTone
                        style={{ marginLeft: "4px", marginTop: "4px" }}
                      />
                    </label>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ),
    },
  ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    if (checkimagepath) {
      if (subCategorytitle && file && category   ) {
        setCurrent(current + 1);
      }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    setselectRequired(true);
    settitleRequired(true);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  // const contentStyle = {
  //   textAlign: "center",
  //   color: token.colorTextTertiary,
  //   backgroundColor: token.colorFillAlter,
  //   borderRadius: token.borderRadiusLG,
  //   border: `1px dashed ${token.colorBorder}`,
  //   marginTop: 16,
  //   padding: 5,
  //   width: "100%",
  //   border: "2px solid grey",
  // };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl  flex-grow-1">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Sub Category List</h4>

                  <Button
                    className="handleBtn "
                    type="primary"
                    onClick={() => {
                      setimagerequred(true);
                      setToggle(!toggle);
                      setSubcategorytitleError(false);
                      settitleRequired(true);
                      setselectRequired(true);
                      setFile("");
                      setCheck("");
                      setupdate(true);
                      setimagepath(false);

                      setCategory("");
                      setSubcategorytitle("");
                    }}
                  >
                    <p>
                      <PlusCircleFilled
                        style={{ display: "inline-flex", marginRight: "6px" }}
                      />{" "}
                      Add Sub Category
                    </p>
                  </Button>
                </div>

                {/* add subcategory add  */}

                {toggle && (
                  <div>
                    {" "}
                    <div style={{}}>
                      <>
                        <div className="handleBox">
                          {steps[current].content}
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <Steps
                            current={current}
                            items={items}
                            style={{ marginBottom: "2%" }}
                          />
                          {current < steps.length - 1 && (
                            <Button
                              className="handleBtn"
                              type="primary"
                              onClick={() => {
                                if (!file) {
                                  setimagerequred(false);
                                }
                                if (category==null) {
                                  setselectRequired(false);
                                }
                                if (!check) {
                                  setselectRequired(false);
                                }
                                if (!subCategorytitle) {
                                  settitleRequired(false);
                                }
                               if(category===""){
                                setselectRequired(false)
                               }
                               
                               if(category!==null){
                                next();
                               }
                               else{
                                setselectRequired(false)
                               }
                              
                              }}
                            >
                              Next
                            </Button>
                          )}
                          &nbsp;&nbsp;
                          {current === steps.length - 1 && (
                            <Button
                              style={{ backgroundColor: "green" }}
                              onClick={() => {
                                if (functioncall) {
                                  onSubmit();
                                  prev();
                                  setToggle(false);
                                } else {
                                  message.success("Add Fields Required");
                                }
                              }}
                            >
                              Save
                            </Button>
                          )}
                          {current > 0 && (
                            <Button
                              style={{ margin: "0 8px" }}
                              className="handleBtn"
                              onClick={() => prev()}
                            >
                              Previous
                            </Button>
                          )}
                          <Button
                            className="handleBtn2"
                            onClick={() => {
                              setToggle(false);

                              settitleRequired(true);
                              setselectRequired(true);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                <div
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  style={{marginTop:'60px'}}
                >
                  <div className="d-flex justify-content-end">
                    <div class="form-outline mt-2 me-1">
                      {/* <label class="form-label" for="form1">
                        Search
                      </label> */}
                      <input
                        style={{ marginBottom: "-38px" }}
                        type="search"
                        id="form1"
                        class="form-control"
                        placeholder="Search"
                        value={searchText}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="m-2 p-lg-0">
                    <small>Sub Categories</small>
                  </div>
                  <hr style={{ color: "black" }} />
                </div>
                <div className="card-body">
                  {isLoaded ? (
                    <Spinner />
                  ) : (
                    <DataTable
                      columns={columns}
                      data={subCategoryList.filter((row) =>
                        row.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )}
                      direction="ltr"
                      fixedHeader
                      highlightOnHover
                      pagination
                      persistTableHead
                      responsive
                      subHeaderAlign="left"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SubCategoryList;
