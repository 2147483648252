import React, { useEffect, useState } from "react";
import SideBar from "../../../components/sideBar";
import Header from "../../../components/header";
import { db } from "../../../firebase";
import { Card, Space, Button, message } from "antd";

import {
  DeleteFilled,
  SaveOutlined,
  UploadOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import AOS from "aos";

import firebase from "firebase/compat/app";

export default function Homesliderlist() {
  const [homeSlider, sethomeslider] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [toggle, setToggle] = useState(false);

  const [file, setFile] = useState(null);

  const [url, setUrl] = useState("");
  const [updateImage, setUpdateImage] = useState(true);
  const [imageRequired, setimagerequred] = useState(true);
  const [Deleteimage, setDeleteimage] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setimagerequred(true);
      setUpdateImage(true);
    }
  };
  //UPLOADE IMAGE
  const handleUpload = () => {
    if (file) {
      const storageRef = firebase.storage().ref();
      console.log(storageRef);
      const uploadTask = storageRef
        .child(`ListingImages/${file.name}`)
        .put(file); // eslint-disable-next-line no-restricted-globals
      // let conf = confirm("Upload")
      // console.log(conf, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.log("Error uploading file:", error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              setUrl(downloadURL);
              // console.log(
              //   "File uploaded successfully. Download URL:",
              //   downloadURL
              // );
            })
            .catch((error) => {
              console.log("Error getting download URL:", error);
            });
        }
      );
    }
    message.success("Image Uploaded Successfully");
    setUpdateImage(false);
  };

  //HOME SLIDER IMAGE GET IN

  const OnSubmit = () => {
  console.log('oooooooooooooooooooooo')
    if (url) {
      console.log("ok");
      console.log("image path", url);
      getHomesliderdata();
      alert("save image ");
      gethomedata();
      setToggle(false);
    } else {
      // alert('please enter image path')
    }
  };
  // useEffect(() => {

  // }, []);
  //////

  //UPDATE image
  const getHomesliderdata = async () => {
    try{
    const homeSliderRef = db.collection("HomeSliderImage").doc("1");
    let HomesliderDoc = await homeSliderRef.get();
     let  HomesliderData = HomesliderDoc.data();
    // console.log('cityDatacityDatacityDatacityData::',HomesliderData.Images)
    if(!HomesliderData){
      db.collection("HomeSliderImage")
      .doc('1')
      .set({
       
        Images:[]
      })
       HomesliderDoc = await homeSliderRef.get();
        HomesliderData = HomesliderDoc.data();
   
    }
   
    
      HomesliderData.Images.push(url);
    // console.log("jjjjjjjjjjjjjjjjjjjjjjj", url);
     await homeSliderRef.update(HomesliderData);

    // gethomedata()

  }

    catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  //get data in homeSLIDER
  const gethomedata = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("HomeSliderImage").get();
      const data = querySnapshot.docs.map((doc) => {
        const categoryData = doc.data();
        // categoryData.id = doc.id;
        return categoryData;
      });
      if(data.length>0){
        console.log("lffflk", data[0].Images);
        setTimeout(() => {
          sethomeslider(data[0].Images);
        }, 1000);
  
      }
    }
     
   
    
    catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };

  //  const db = getFirestore();

  const Delete = (index) => {
    console.log(index);
    const data = homeSlider.splice(index, 1);
    setDeleteimage(data);
    // console.log("kkkkkkkkkkkkkkkkkkkkk", homeSlider);
    // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhh", data);
    getHomesliderdata1();
    gethomedata();
  };
  //delete home slider update
  const getHomesliderdata1 = async () => {
    try {
      const homeSliderRef = db.collection("HomeSliderImage").doc("1");
      const HomesliderDoc = await homeSliderRef.get();
      const HomesliderData = HomesliderDoc.data();
      console.log("cityDatacityDatacityDatacityData::", HomesliderData.Images);
      HomesliderData.Images = homeSlider;
      // console.log("ppppppppppppppppppppppppp", HomesliderData);
      await homeSliderRef.update(HomesliderData);
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  // console.log("data is llllllllllllllllll ", homeSlider);
  useEffect(() => {
     gethomedata()
   
    AOS.init();
  }, []);

  // console.log("Delete image", Deleteimage);

  return (
    <div>
      <div className="App">
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <SideBar />

            <div className="layout-page">
              <Header />
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1">
                  <div className="d-flex py-3 align-items-center justify-content-between">
                    <h4 className="fw-bold">Home slider</h4>
                  </div>
                  <div className="d-flex py-3 align-items-center justify-content-between">
                    <h4 className="fw-bold"></h4>

                    <Button
                      className="handleBtn"
                      onClick={() => {
                        setToggle(!toggle);
                        setimagerequred(true);
                        setFile("");
                        setUpdateImage(false);

                        setUrl("");
                      }}
                    >
                      {" "}
                      <PlusCircleFilled
                        style={{ display: "inline-flex", marginRight: "6px" }}
                      />
                      Add Home Slider
                    </Button>
                  </div>

                  {/* add image  toggle */}
                  {toggle && (
                    <div>
                      {" "}
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="card mb-4 card-header">
                            <div className="card-body card-header">
                              <div className="row align-items-end">
                                <div className="col-md-4 mt-4">
                                  <label>Home slider image</label>
                                  <input
                                    type="file"
                                    onChange={handleImageChange}
                                    accept="image/gif, image/jpeg"
                                    style={{
                                      border: " 1px solid #337ab7",
                                      width: "13rem",
                                    }}
                                  />
                                  {file && (
                                    <div>
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt="Selected"
                                        height={90}
                                        width={100}
                                      />
                                      <br />
                                    </div>
                                  )}
                                  {updateImage && (
                                    <div>
                                      <Button
                                        style={{ marginTop: "6px" }}
                                        size="small"
                                        className="handleBtn"
                                        onClick={handleUpload}
                                      >
                                        {" "}
                                        <UploadOutlined />
                                        Upload
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                {imageRequired ? (
                                  <div></div>
                                ) : (
                                  <div>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      Required
                                    </span>
                                  </div>
                                )}

                                <div className="col-md-4 mt-4"></div>
                                <div>
                                  <Button
                                    className="handleBtn"
                                    style={{ margin: 10 }}
                                    onClick={() => {
                                      if (file) {
                                        OnSubmit();
                                      }
                                      if (!file) {
                                        setimagerequred(false);
                                      }

                                      // setToggle(true)
                                    }}
                                    // disabled={!latitude || !longitude || !categorySliderImage}
                                  >
                                    Save Data
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="m-2 p-lg-0">
                    <hr />

                    <div className="row">
                      {homeSlider &&
                        homeSlider.length > 0 &&
                        homeSlider.map((item, index) => (
                          <Card
                            key={index} // Add a unique key to each Card element
                            hoverable
                            style={{
                              width: 120,
                              margin: "5px",
                              height: 120,
                            }}
                            cover={
                              <img alt="example" src={item} height={110} />
                            }
                          >
                            <br />
                            <Space wrap>
                              <Button
                                className="handleBtn"
                                style={{
                                  position: "absolute",
                                  width: "100",
                                  margin: -38,
                                  marginLeft: -15,
                                }}
                                onClick={() => Delete(index)} // Pass the index directly to Delete function
                                size="small"
                              >
                                {/* <label>
                                  
                                    <DeleteFilled className="handleDeleteIcon" />
                                  
                                </label> */}
                                Delete
                              </Button>
                            </Space>
                          </Card>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
