

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const env = { ...process.env, ...window['env'] }

//Mousam
const firebaseConfig = {
  apiKey: env. REACT_APP_apiKey,
  authDomain: env. REACT_APP_authDomain,
  projectId: env. REACT_APP_projectId,
  storageBucket: env. REACT_APP_storageBucket,
  messagingSenderId: env. REACT_APP_messagingSenderId,
  appId: env. REACT_APP_appId,
  measurementId: env. REACT_APP_measurementId
};




const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };


