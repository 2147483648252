import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import locales from "../../src/locales/en.json";
import imglogo from "../../src/assest/logo.png";

import {
  HomeOutlined,
  CheckOutlined,
  BookOutlined,
  CommentOutlined,
  ShopOutlined,
} from "@ant-design/icons";

import "./sidebar.css";

function SideBar() {
  let navigate = useNavigate();
  const logOutHandler = () => {
    localStorage.clear();
    navigate("/");
  };

  const [pageName, setPageName] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    // console.log(localStorage.getItem('role'))
    if (localStorage.getItem("role")) {
      setRole(localStorage.getItem("role"));
    }
    console.log();
    setPageName(window.location.href);
  }, []);
  function closeSideBar() {
    document.querySelector("#layout-menu").classList.remove("expand-sidebar");
    document.getElementById("layout-menu-toggle").classList.remove("d-none");
    document.getElementById("layout-menu-toggle").classList.remove("d-none");
    document.getElementById("layout-menu-close").classList.add("d-none");
  }

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo">
        <img src={imglogo} width="30" alt="image" />
        {/* <Link
    to={role === "admin" ? "/categoryList" : "/productList"}
    className="app-brand-link"
  >
 
      
    <span className="app-brand-logo demo"></span>
 
 

  </Link> */}
        <span
          id="rentors-plus"
          className="app-brand-text demo menu-text fw-bolder ms-2"
        >
          Rentors Plus
        </span>

        {/* <Link
    to=""
    className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
  >
   <img alt="Logo" src={"../../public/logo192.png"} className="h-30px" />
    <i className="bx bx-chevron-left bx-sm align-middle"></i>
  </Link> */}
      </div>
      <span
        id="layout-menu-close"
        className="layout-menu-toggle menu-link text-large ms-auto d-xl-none d-none"
        onClick={() => closeSideBar()}
      >
        <i className="bx bx-chevron-left bx-sm align-middle"></i>
      </span>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
        {role === "user" ? (
          <>
            <li
              className={[
                "menu-item",
                pageName.includes("/priceList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/priceList" className="menu-link">
                <i className="menu-icon tf-icons bx bx-cube-alt"></i>
                <div data-i18n="Basic">{locales.Price}</div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/sizeProfileList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/sizeProfileList" className="menu-link">
                <i className="menu-icon tf-icons bx bx-copy"></i>
                <div data-i18n="Basic">{locales.SizeProfiles}</div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/interface") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/interface" className="menu-link">
                <i className="menu-icon tf-icons bx bx-export"></i>
                <div data-i18n="Basic">{locales.Interface}</div>
              </Link>
            </li>
          </>
        ) : (
          <>
            <li
              className={[
                "menu-item",
                pageName.includes("/categoryList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/categoryList" className="menu-link">
                <i className="menu-icon bx bxs-category-alt"></i>
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <div data-i18n="Basic" style={{ marginLeft: "-7px" }}>
                  Category
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/subCategoryList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/subCategoryList" className="menu-link">
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <i className=" menu-icon bx bxs-category"></i>
                <div data-i18n="Basic" style={{ marginLeft: "-7px" }}>
                  Sub Category
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/cityList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/cityList" className="menu-link">
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <i className=" menu-icon bx bxs-business"></i>
                <div data-i18n="Basic" style={{ marginLeft: "-7px" }}>
                  City
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/HomeSliderlist") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/HomeSliderlist" className="menu-link">
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <HomeOutlined
                  style={{
                    fontSize: "20px",
                    color: "white",
                    // margin: 5,
                    marginLeft: "-1px",
                  }}
                />
                <div data-i18n="Basic" style={{ marginLeft: "6px" }}>
                  Home Slider
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/vendorProductList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/vendorProductList" className="menu-link">
                <ShopOutlined
                  style={{
                    fontSize: "20px",
                    color: "white",
                    // margin: "5px",
                  }}
                />
                <div data-i18n="Basic" style={{ marginLeft: "5px" }}>
                  Product List
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/subscriptionPlan") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/subscriptionPlan" className="menu-link">
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <CheckOutlined
                  style={{
                    fontSize: "20px",
                    color: "white",
                    margin: 5,
                    marginLeft: "-1px",
                  }}
                />
                <div data-i18n="Basic" style={{ marginLeft: "1px" }}>
                  Packages
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/freeTrial") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/freeTrial" className="menu-link">
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <BookOutlined
                  style={{
                    fontSize: "20px",
                    color: "white",
                    margin: 5,
                    marginLeft: "-1px",
                  }}
                />
                <div data-i18n="Basic" style={{ marginLeft: "3px" }}>
                  Free Trial
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/featuredPlan") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/featuredPlan" className="menu-link">
                <i className="menu-icon bx bxs-coin-stack"></i>
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <div data-i18n="Basic" style={{ marginLeft: "-6px" }}>
                  Featured Plan
                </div>
              </Link>
            </li>

            <li
              className={[
                "menu-item",
                pageName.includes("/userList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/userList" className="menu-link">
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <i className="menu-icon bx bxs-user-account"></i>
                <div data-i18n="Basic" style={{ marginLeft: "-6px" }}>
                  Users
                </div>
              </Link>
            </li>
            <li
              className={[
                "menu-item",
                pageName.includes("/product_booking_list") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/product_booking_list" className="menu-link">
                <ShopOutlined
                  style={{
                    fontSize: "20px",
                    color: "white",
                    margin: 5,
                    marginLeft: "-1px",
                  }}
                />
                <div data-i18n="Basic">Bookings</div>
              </Link>
            </li>

            <li
              className={[
                "menu-item",
                pageName.includes("/commissionList") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/commissionList" className="menu-link">
                <i className="menu-icon bx bxs-coin-stack"></i>
                {/* <i className="menu-icon tf-icons bx bx-wallet"></i> */}
                <div data-i18n="Basic" style={{ marginLeft: "-9px" }}>
                  Commission{" "}
                </div>
              </Link>
            </li>

            <li
              className={[
                "menu-item",
                pageName.includes("/notification") ? "active" : "",
              ].join(" ")}
            >
              <Link to="/notification" className="menu-link">
                <CommentOutlined
                  style={{
                    fontSize: "20px",
                    color: "white",
                    margin: 5,
                    marginLeft: "1px",
                  }}
                />
                <div data-i18n="Basic">Notifications </div>
              </Link>
            </li>

            {/* 
      <li
        className={[
          "menu-item",
          pageName.includes("/price") ? "active" : "",
        ].join(" ")}
      >
        <Link to="/price" className="menu-link">
          <i className="menu-icon tf-icons bx bx-box"></i>
          <div data-i18n="Basic">Price</div>
        </Link>
      </li> */}
          </>
        )}

        <li className={["menu-item"].join(" ")}>
          <Link
            to="/"
            className="menu-link menu-toggle"
            onClick={() => logOutHandler()}
          >
            <i className="bx bx-power-off me-2"></i>
            <span className="align-middle">{locales.LogOut}</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
}
export default SideBar;
