import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { PlusCircleOutlined } from "@ant-design/icons";
import { db } from "../../firebase";
import { Button, message, Icon } from "antd";
import { Modal } from "react-bootstrap";
import firebase from "firebase/compat/app";
import { set } from "lodash";

function CategoryList() {
  const [categoryList, setCategoryList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [showModal, setShowModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [toggle, setToggle] = useState(false);
  const [file, setFile] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryTitleError, setCategoryTitleError] = useState("");
  const [category, setCategory] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [url, setUrl] = useState("");
  const [titleRequired, settitleRequired] = useState(true);
  const [imageRequired, setimagerequred] = useState(true);
  const [update, setupdate] = useState(true);
  const [checkimagepath, setimagepath] = useState(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setimagerequred(true);
    }
  };

  const handleUpload = () => {
    if (file) {
      setimagepath(true);
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(`ListingImages/${file.name}`)
        .put(file); // eslint-disable-next-line no-restricted-globals
      // let conf = confirm("Upload")
      // console.log(conf, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.log("Error uploading file:", error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              setUrl(downloadURL);
             
            })
            .catch((error) => {
              console.log("Error getting download URL:", error);
            });
        }
      );
    }
    message.success("Image Uploaded Successfully");
    setupdate(false);
  };

  const OnSubmit = () => {
    console.log("KKKKKKKKKKKKKK");
    let isValid = true;

    if (categoryTitle === "") {
      setCategoryTitleError("Required");
      isValid = false;
    }
    if (!file) {
      isValid = false;
    }

    if (isValid && checkimagepath) {
      const id = Math.random().toString();
      try {
        db.collection("category")
          .doc(id)
          .set({
            id: id,
            created_at: new Date(),
            name: categoryTitle,
            image: url,
            active: isActive,
            sub_category: category.map((subCat) => ({
              id: subCat.name, // Include the sub-category's ID
              // active: true // Set the active field to true for all sub-categories
            })),
          })
          .then(() => {
            setToaster({
              type: "success",
              title: "Successfull",
              text: "category created successfully",
              visiblity: "show",
            });

            // setCategorySliderImage("");
            setCategoryTitle("");
            // window.location.reload(false)

            setTimeout(() => {
              setToaster({
                visiblity: "hide",
              });
            }, 1500);
          });
      } catch (err) {
        console.log(err, file?.name);
        setToaster({
          type: "data failed",
          title: "Error Occured",
          text: "unsuccessful ",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
      }
      alert("Category save ");
    }

    getCategory1();

    getCategory1();
  };
  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const querySnapshot = await db.collection("category").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
   //   console.log("Category data:", data);
      setCategory(data);
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };
  //

  useEffect(() => {
    getCategory();
    getCategory1();
    AOS.init();
  }, []);

  const handleToggle = (id) => {
    setSelectedCategoryId(id);
    setShowModal(true);
  };

  const handleConfirmation = async () => {
    try {
      if (selectedCategoryId) {
        const categoryRef = db.collection("category").doc(selectedCategoryId);
        const snapshot = await categoryRef.get();

        if (snapshot.exists) {
          const category = snapshot.data();
          const updatedData = {
            active: !category.active,
          };
          await categoryRef.update(updatedData);

          const updatedCategoryList = categoryList.map((category) => {
            if (category.id === selectedCategoryId) {
              return {
                ...category,
                active: !category.active,
              };
            }
            return category;
          });

          setCategoryList(updatedCategoryList);
     //     console.log("mmmmmmmmmmmmmmmmmmmm", updatedCategoryList);
        }
      }
    } catch (error) {
      console.error("Error toggling category status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle category status",
        visibility: "show",
      });
    }

    setShowModal(false);
  };

 
  const handleCancel = () => {
    setShowModal(false);
  };

  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Category Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Icon Image",
      cell: (row) => <img src={row.image} alt="Category Icon" width="50" />,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.active,
      sortable: true,
      cell: (row) => (
        <div>
          {row.active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger ">Inactive</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`/categoryDetail/${row.id}`} className="menu-link">
            <i className="menu-icon tf-icons bx bx-edit"></i>
          </Link>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`toggleSwitch_${row.id}`}
              checked={!!row.active}
              onChange={() => handleToggle(row.id)}
            />
            <label
              className="form-check-label"
              htmlFor={`toggleSwitch_${row.id}`}
            ></label>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const getCategory1 = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("category").get();
      const data = querySnapshot.docs.map((doc) => {
        const categoryData = doc.data();
        categoryData.id = doc.id;
        return categoryData;
      });
      setCategoryList(data);
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold">Category List</h4>
                  {/* <Link to="/addCategory" className="btn btn-primary" type="button">
                    Add Category
                  </Link> */}
                  <Button
                    className="handleBtn"
                    type="primary"
                    onClick={() => {
                      setToggle(!toggle);
                      setFile("");
                      settitleRequired(true);
                      setimagerequred(true);
                      setCategoryTitleError(false);
                      setupdate(true);
                      setimagepath(false);
                    }}
                    style={{}}
                  >
                    {" "}
                    <p
                      style={{
                        display: "inline-flex",
                        marginRight: "6px",
                        marginTop: "-0.1rem",
                      }}
                    >
                      <PlusCircleOutlined />
                    </p>
                    Add Category
                  </Button>
                </div>
                {toggle && (
                  <div>
                    <div className="card mb-4 card-header">
                      <div className="card-body card-header">
                        <div className="row align-items-end">
                          <div className="col-md-4 mt-4">
                            <label className="form-label">Category Title</label>
                            <input
                              type="text"
                              pattern="[0-9]*"
                              inputMode="numeric"
                              className="form-control"
                              value={categoryTitle}
                              onChange={(e) => {
                                if (e.target.value !== "") {
                                  setCategoryTitleError("");
                                  settitleRequired(true);
                                }

                                // console.log("hey this side mo\sam", e.target.value.length);
                                if (e.target.value.length <= 1) {
                                  setCategoryTitle(e.target.value.trim());
                                } else {
                                  setCategoryTitle(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => {
                                settitleRequired(false);
                                if (e.key === "Enter") {
                                  settitleRequired(true);
                                  console.log("do validate");
                                }
                                e.key === "Enter"
                                  ? OnSubmit()
                                  : setCategoryTitle(e.target.value);
                              }}
                            />
                            {titleRequired ? (
                              <div></div>
                            ) : (
                              <div>
                                <span style={{ color: "red" }}>Required</span>
                              </div>
                            )}
                            {/* {categoryTitleError ? (<div><span style={{ color: 'red' }}> {categoryTitleError}</span></div>):''} */}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label">
                              Category slider image
                            </label>
                            <br />
                            <input
                              type="file"
                              onChange={handleImageChange}
                              accept="image/gif, image/jpeg"
                              style={{
                                border: " 1px solid #337ab7",
                                width: "13rem",
                              }}
                            />
                            {file && (
                              <div>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt="Selected"
                                  height={100}
                                  width={100}
                                />
                                {update && (
                                  <div>
                                    <Button
                                      className="handleBtn"
                                      style={{ marginTop: "6px" }}
                                      onClick={() => {
                                        handleUpload();
                                        // setimagerequred(true)
                                      }}
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )}
                            {imageRequired ? (
                              <div></div>
                            ) : (
                              <div>
                                <span style={{ color: "red" }}> Required</span>
                              </div>
                            )}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label">Status</label>
                            <div className="col-md-8 col-sm-6 col-xs-6">
                              <input
                                type="radio"
                                name="cstatus"
                                value="1"
                                checked={isActive}
                                onChange={() => setIsActive(true)}
                              />
                              <label className="radio-label">Active</label>
                              <input
                                type="radio"
                                name="cstatus"
                                value="0"
                                checked={!isActive}
                                onChange={() => setIsActive(false)}
                              />
                              <label className="radio-label">Inactive</label>
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <Button
                              className="handleBtn"
                              type="primary"
                              onClick={() => {
                                if (!file) {
                                  setimagerequred(false);
                                }
                                if (categoryTitle) {
                                  settitleRequired(true);
                                } else {
                                  settitleRequired(false);
                                }

                                if (categoryTitle && file && checkimagepath) {
                                  OnSubmit();
                                  setToggle(false);
                                }
                              }}
                              // disabled={!latitude || !longitude || !categorySliderImage}
                            >
                              Save Data
                            </Button>
                            <Button
                              className="handleBtn"
                              type="primary"
                              style={{ margin: "5px" }}
                              onClick={() => {
                                setToggle(false);
                                setupdate(true);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                )}

                <div style={{ borderRadius: "15px" }}>
                  <div
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <div className="d-flex justify-content-end">
                      <div class="form-outline mt-4 me-4">
                        {/* <label class="form-label" for="form1">Search</label> */}
                        <input
                          type="search"
                          id="form1"
                          class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="m-2 p-lg-0">
                      <small>categories</small>
                    </div>
                    <hr />
                  </div>
                  <div className="card-body">
                    {isLoaded ? (
                      <Spinner />
                    ) : (
                      <DataTable
                        columns={columns}
                        data={categoryList.filter((row) =>
                          row.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )}
                        direction="ltr"
                        fixedHeader
                        highlightOnHover
                        pagination
                        persistTableHead
                        responsive
                        subHeaderAlign="left"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CategoryList;
