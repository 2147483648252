import { useNavigate, useParams } from "react-router-dom";
// import services from "../../services";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import React from "react";
import Toaster from "../../components/toaster";
import Spinner from "../../components/spinner";
import locales from "../../locales/en.json"


function StoreDetail(props) {
    let navigate = useNavigate();
    const routeParams = useParams();


    const [storeList, setStoreList] = useState([])
    const [storeNameObj, setStoreNameObj] = useState({ val: '', isValid: true, err: 'required' })
    const [emailObj, setEmailObj] = useState({ val: '', isValid: true, err: 'required' })
    const [passwordObj, setPasswordObj] = useState({ val: '', isValid: true, err: 'required' })
    const [toaster, setToaster] = useState({ visiblity: 'hide' })
    // const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        getStoreList();
    }, []);
    const getStoreList = async () => {
        // setIsLoaded(false);

        // try {
        //     const response = await services.Store.GET_STORES();
        //     // setIsLoaded(true);
        //     setStoreList(response.data);
        // } catch (error) {
        //     // setIsLoaded(true);
        // }
    };
    function setEmailHandler(val) { // eslint-disable-next-line 
        if (!val) {
            setEmailObj({
                isValid: false,
                err: 'required'
            })
        }
        else {
            setEmailObj({
                isValid: true,
                err: ``,
                val: val
            })
        }
    }
    function setPasswordHandler(val) { // eslint-disable-next-line 
        if (!val) {
            setPasswordObj({
                isValid: false,
                err: 'required'
            })
        }
        else {
            setPasswordObj({
                isValid: true,
                err: ``,
                val: val
            })
        }
    }
    function storeNameHandler(val) {
        if (!val) {
            setStoreNameObj({
                isValid: false,
                err: "required",
            });
        } else {
            setStoreNameObj({
                isValid: true,
                err: "",
                val: val,
            });
        }
    }

    function submitButtonHandler() {
        if (emailObj.val && passwordObj.val) {
            const email = emailObj.val
            const password = passwordObj.val
            setEmailObj({ val: email, isValid: true })
            setPasswordObj({ val: password, isValid: true })
            createUser()
        } else {
            setEmailObj({ isValid: false, err: 'required' })
            setPasswordObj({ isValid: false, err: 'required' })
        }
    }

    const createUser = async () => {
        const data = {
            email: emailObj?.val,
            password: passwordObj?.val,
            shopId: storeNameObj.val

        }
        // try {
        //     const response = await services.auth.REGISTER(data)
        //     if (response.data) {

        //         setToaster({
        //             type: 'success',
        //             title: 'Successfull',
        //             text: "user created successfully",
        //             visiblity: 'show'
        //         })
        //         setTimeout(() => {
        //             navigate('/user')
        //         }, 700)
        //     }
        // } catch (error) {

        //     setToaster({
        //         type: 'danger',
        //         title: 'Error Occured',
        //         text: error.response.data.message,
        //         visiblity: 'show'
        //     })
        //     setTimeout(() => {
        //         setToaster({
        //             visiblity: 'hide'
        //         })
        //     }, 1500)
        // }
    }

    return (
        <div className="App">
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Toaster type={toaster.type} title={toaster.title} text={toaster.text} visiblity={toaster.visiblity} />
                    <SideBar />
                    {/* {!isLoaded && <Spinner />} */}
                    <div className="layout-page">
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row py-3 mb-4 align-items-center">
                                    <div className="col-lg-3">
                                        <h4 className="fw-bold pt-3">Add User</h4>
                                    </div>
                                    <div className="col-lg-9 text-end">
                                        <div className="mt-3">
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className='row align-items'>
                                                    <div className="col-md-4 mt-4">
                                                        <label
                                                            className="form-label">{locales.email}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            inputMode="text"
                                                            onChange={(e) => setEmailHandler(e.target.value)}
                                                        />{!emailObj.isValid && <span
                                                            className="position-absolute alert-danger mt-2">
                                                            {emailObj.err}</span>}
                                                    </div>
                                                    <div className="col-md-4 mt-4">
                                                        <label
                                                            className="form-label">{locales.password}</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            inputMode="text"
                                                            onChange={(e) => setPasswordHandler(e.target.value)}
                                                        />{!passwordObj.isValid && <span
                                                            className="position-absolute alert-danger mt-2">
                                                            {passwordObj.err}</span>}
                                                    </div>
                                                    <div className="col-md-4 mt-4">
                                                        <label
                                                            htmlFor="exampleFormControlSelect1"
                                                            className="form-label"
                                                        >
                                                            {locales.StoreName}
                                                        </label>
                                                        {
                                                            <select
                                                                className="form-select"
                                                                id="exampleFormControlSelect1"
                                                                aria-label="Default select example"
                                                                onChange={(e) =>
                                                                    storeNameHandler(e.target.value)
                                                                }
                                                                value={storeNameObj.val}
                                                            >
                                                                <option value={""}>Select Store</option>
                                                                {storeList && storeList.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.id}>
                                                                            {item.storeName}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        }
                                                        {!storeNameObj.isValid && (
                                                            <span className="position-absolute alert-danger mt-2">
                                                                {storeNameObj.err}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='col-md-8 mt-4 text-end'>
                                                    <button type="" disabled={!(emailObj.isValid && passwordObj.isValid)}
                                                        className="btn btn-primary me-2"
                                                        onClick={submitButtonHandler}
                                                    >{locales.SaveData}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoreDetail;

