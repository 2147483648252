import { useEffect, useState } from "react";
// import services from "../../services";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Table from "react-bootstrap/Table";

import Toaster from "../../components/toaster";
import { db } from "../../firebase";

import { Form } from "react-bootstrap";

import "../../paln.css";
import "aos/dist/aos.css";

function SubscriptionPlan() {
  //const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  // const [showModal, setShowModal] = useState(false);
  //const [selectedProductId, setSelectedProductId] = useState(null);

  const [plan, getplan] = useState([]);
  const [users, setusers] = useState([]);
  const [toggle, setToggle] = useState(false);

  const [isActive, setIsActive] = useState(true);

  const [currency_type, setCurrency] = useState("");
  const [period, setperiod] = useState("");
  const [price, setprice] = useState("");
  const [products, setproduct] = useState("");
  const [title, settitle] = useState("");
  const [titleerror, settitleerror] = useState("");
  const [pricerror, setpriceerror] = useState("");
  const [producterroe, setproducterror] = useState("");
  const [description, setdescription] = useState("");
  const [descriptionerror, setdescriptionerroe] = useState("");
  const [perioderror, setperioderror] = useState("");
  const [currency_typeError, setCurrencyError] = useState("");

  //getplans
  const getplans = async () => {
    try {
      const querySnapshot = await db.collection("SubscriptionList").get();
      const data = querySnapshot.docs.map((doc) => {
        const planData = doc.data();
        // categoryData.id = doc.id;
        return planData;
      });
    //  console.log("lffflk", data);
      getplan(data);
      // setProductsArray((homeSlider => homeSlider.filter((_, index) => index !== 0)));
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  //get users
  const getusers = async () => {
    try {
      const querySnapshot = await db.collection("users").get();
      const data = querySnapshot.docs.map((doc) => {
        const planData = doc.data();
        // categoryData.id = doc.id;
        return planData;
      });
   //   console.log("lffflk", data);
      setusers(data);
      // setProductsArray((homeSlider => homeSlider.filter((_, index) => index !== 0)));
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };

  useEffect(() => {
    getplans();
    getusers();
  }, []);

//  console.log("kkkkkkkkkk", users.firstName);
  //  ///////////////////////////////////////////////////////////////
  //plan add in sotre
  const OnSubmit = () => {
    console.log(
      "KKKKKKKKKKKKKK",
      price,
      currency_type,
      description,
      period,
      title,
      products
    );
    let isValid = true;

    if (title === "") {
      settitleerror("Required");
      isValid = false;
    }
    if (price === "") {
      setpriceerror("Required");
      isValid = false;
    }
    if (description === "") {
      setdescriptionerroe("Required");
      isValid = false;
    }
    if (products === "") {
      setproducterror("Required");
      isValid = false;
    }
    if (period === "") {
      setperioderror("Required");
      isValid = false;
    }

    if (currency_type === "") {
      setCurrencyError("Requred");
      isValid = false;
    }

    if (isValid) {
      setToggle(false);
      const id = Math.random().toString();
      try {
        db.collection("SubscriptionList")
          .doc(id)
          .set({
            id: id,
            created_at: new Date(),
            plan_name: title,
            period: period,
            products: products,
            price: price,
            description: description,
            currency_type: currency_type,

            status: isActive ? 1 : 0, // 1 for active, 0 for inactive
            updated_at: new Date(),

            // created_by: "Admin",
          })

          .then(() => {
            setToaster({
              type: "success",
              title: "Successfull",
              text: "Subscription List was successfully created",
              visiblity: "show",
            });

            settitle("");
            // window.location.reload(false)

            setTimeout(() => {
              setToaster({
                visiblity: "hide",
              });
            }, 1500);
          });
      } catch (err) {
        console.log(err.name);
        setToaster({
          type: "data failed",
          title: "Error Occured",
          text: "unsuccessful ",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
      }
    }
    getplans();
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                {/* <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Vendor List</h4>
                </div> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="handleBtn1"
                    type="primary"
                    onClick={() => {
                      setToggle(!toggle);
                      setperiod("");
                      setprice("");
                      setdescription("");
                      setCurrency("");
                      settitle("");
                      setdescriptionerroe("");
                      setpriceerror("");
                      setproducterror("");
                      settitleerror("");
                      setproduct("");
                      setCurrencyError("")
                      setperioderror("")
                    }}
                  >
                    <PlusCircleOutlined
                      className="handleBtn"
                      style={{ display: "inline-flex",     marginRight: '-2px',
    marginTop: '4px' }}
                    />{" "}
                    Add Plan
                  </Button>
                  <Button type="primary" className="handleBtn1 ">
                    {" "}
                    Total Subscription Revenue USD 2000
                  </Button>
                </div>
                {/* data add in sotre  */}
                {toggle && (
                  <div>
                  <br/>
                    <label className="form-label">paln name :</label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      className="form-control"
                      value={title}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          settitleerror("");
                        }
                        if (e.target.value.length <= 1) {
                          settitle(e.target.value.trim());
                        } else {
                          settitle(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        e.key === "Enter"
                          ? OnSubmit()
                          : settitle(e.target.value);
                      }}
                    />
                    {titleerror ? (
                      <div>
                        <span style={{ color: "red" }}>{titleerror}</span>
                      </div>
                    ) : null}
                    <br/>
                    <label className="form-label"> NO. of products :</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      className="form-control"
                      value={products}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setproducterror("");
                        }
                        setproduct(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        e.key === "Enter"
                          ? OnSubmit()
                          : setproduct(e.target.value);
                      }}
                    />
                    {producterroe ? (
                      <div>
                        <span style={{ color: "red" }}>{producterroe}</span>
                      </div>
                    ) : null}
                    <br/>
                    <label className="form-label"> Price :</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      className="form-control"
                      value={price}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setpriceerror("");
                        }
                        setprice(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        e.key === "Enter"
                          ? OnSubmit()
                          : setprice(e.target.value);
                      }}
                    />
                    {pricerror ? (
                      <div>
                        <span style={{ color: "red" }}>{pricerror}</span>
                      </div>
                    ) : null}
                    <br/>
                    <label className="form-label">CURRENCY TYPE :</label>
                    <Form.Select
                      value={currency_type || ""}
                      onChange={(e) => {
                        if (e.target.value) {
                          setCurrency(e.target.value);
                        }
                        if (e.target.value !== "") {
                          setCurrencyError("");
                        }
                      }}
                    >
                      <option value={""}>Open this select menu</option>
                      <option value="usd">USD</option>
                      <option value="EROD">EROD</option>
                    </Form.Select>

                    {currency_typeError ? (
                      <div>
                        <span style={{ color: "red" }}>{perioderror}</span>
                      </div>
                    ) : null}
                    <br/>
                    <label className="form-label">period :</label>

                    <Form.Select
                      value={period || ""}
                      onChange={(e) => {
                        if (e.target.value) {
                          setperiod(e.target.value);
                        }
                        if (e.target.value !== "") {
                          setperioderror("");
                        }
                      }}
                    >
                      <option value={""}>Open this select menu</option>
                      <option value="week">week</option>
                      <option value="month">month</option>
                      <option value="year">year</option>
                    </Form.Select>
                    {perioderror ? (
                      <div>
                        <span style={{ color: "red" }}>{perioderror}</span>
                      </div>
                    ) : null}
                    <br/>
                    <label className="form-label"> DESCRIPTION :</label>
                    <input
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setdescriptionerroe("");
                        }
                        if (e.target.value.length <= 1) {
                          setdescription(e.target.value.trim());
                        } else {
                          setdescription(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        e.key === "Enter"
                          ? OnSubmit()
                          : setdescription(e.target.value);
                      }}
                    />
                    {descriptionerror ? (
                      <div>
                        <span style={{ color: "red" }}>{descriptionerror}</span>
                      </div>
                    ) : null}

                    <Button
                      className="handleBtn"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        OnSubmit();
                      }}
                    >
                      update
                    </Button>
                  </div>
                )}
                {/* show data in card */}
                <div className="row">
                  {plan.map((e) => (
                    <div className="col-md-2" id="col-md-2">
                      <div className="card" id="card">
                        <div className="card-body" id="card-body">
                          <h4 id="planName">{e.plan_name} </h4>
                          <p className="card-text">{e.period}</p>
                          <p className="card-text">
                            {" "}
                            No.of product:{e.products}
                          </p>
                          <p className="card-text">
                            <b>${e.price}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  <div className="d-flex justify-content-end">
                    {/* <div class="form-outline mt-4 me-4">
                        <label class="form-label" for="form1">Search</label>
                        <input type="search" id="form1" class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch} />

                      </div> */}
                  </div>
                  <div className="m-2 p-lg-0">
                    <small>Vendor Product</small>
                  </div>
                  <hr />
                </div>
                <div>
                  {/* tabel  */}

                  <Table>
                    <thead style={{ backgroundColor: "#e5e5e5" }}>
                      <tr id="handletHead">
                        <th>S. No</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email Id</th>
                        <th>Phone No.</th>
                        <th>Subsciption Plan</th>
                        <th>Status</th>
                        <th>Start Date</th>
                        <th>Total Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td>{item.email}</td>
                          <td>{item.number}</td>
                          <td> Basic plan's </td>
                          <td id="handleActiveBtn1">Active</td>
                          <td>{item.createdAt.nanoseconds}</td>

                          <td>$5000</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default SubscriptionPlan;
