import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import moment from 'moment';


function MealsList() {
  const [mealsList, setMealsList] = useState();  // eslint-disable-next-line
  const [toaster, setToaster] = useState({ visiblity: "hide" });

  useEffect(() => {
    getMealsList(); // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },

    // {
    //     name: 'Meals Code',
    //     selector: row => row.code,
    //     sortable: true,
    // },
    {
      name: "Meals Image",
      selector: (row) => row.images,
      sortable: true,
    },
    {
      name: "Latitude",
      selector: (row) => row.latitude,
      sortable: true,
    },
    {
      name: "Longitude",
      selector: (row) => row.longitude,
      sortable: true,
    },
  
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
  
    {
      name: "Website",
      selector: (row) => row.website,
      sortable: true,
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
      sortable: true,
    },

    {
      name: "createdby",
      selector: (row) => row.created_by,
      sortable: true,
    },
    

    {
      name: "Time",
      selector: (row) => moment.unix(row.time).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      sortable: true,
    },
    // {
    //     name: 'Action',
    //     cell: row => <Link to={`/actions`} className="menu-link">
    //         <i className="menu-icon tf-icons bx bx-edit"></i>
    //     </Link>,
    //     allowOverflow: true,
    //     button: true,
    //     width: '56px',
    // },
  ];

  const getMealsList = async () => {
    db.collection("Meals")
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((element) => {
          data.push({ ...element.data() });
          console.log(data);
        });
        setMealsList(data);
      });
  };
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                {/* button */}
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">MealsList</h4>
                  <Link to="/meals" className="btn btn-primary" type="button">
                    Add Meals
                  </Link>
                </div>
                <div className="card">
                  <DataTable
                    columns={columns}
                    data={mealsList}
                    direction="ltr"
                    fixedHeader
                    // fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    persistTableHead
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    // customStyles={customStyles}
                  />
                </div>
              </div>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default MealsList;
