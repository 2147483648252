import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import services from "../../services";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";

function FeaturedPlan() {
  const [featuredPlanList, setFeaturedPlanList] = useState();
  const [isLoaded, setIsLoaded] = useState(false); // eslint-disable-next-line
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [data, setData] = useState();

  useEffect(() => {
    getFeaturedPlan();
    AOS.init();
  }, []);
  const handleToggle = async (id) => {
    try {
      const featuredPlanRef = db.collection("featuredPlan").doc(id);

      const featuredPlanDoc = await featuredPlanRef.get();
      const featuredPlanData = featuredPlanDoc.data();

      const updatedData = {
        ...featuredPlanData,
        status: !featuredPlanData.status,
      };
      await featuredPlanRef.update(updatedData);

      const updatedFeaturedPlanList = featuredPlanList.map((featuredPlan) => {
        if (featuredPlan.id === id) {
          return {
            ...featuredPlan,
            status: !featuredPlan.status,
          };
        }
        getFeaturedPlan();
        return featuredPlan;
      });
      setFeaturedPlanList(updatedFeaturedPlanList);
    } catch (error) {
      console.error("Error toggling featuredPlan status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle featuredPlan status",
        visibility: "show",
      });
    }
  };
  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Featured Plan",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) =>
        moment.unix(row.created_at).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    //   {
    //     name: "Time",
    //     selector: (row) => moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format("MMMM Do, YYYY [at] h:mm:ss A"),
    //     sortable: true,
    //   },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.status ? (
            <span className="badge bg-success">Approved</span>
          ) : (
            <span className="badge bg-danger">Rejected</span>
          )}
        </div>
      ),
    },

    {
      name: "Total Amount",
      selector: (row) => {
        switch (row.period.toString().toLowerCase()) {
          case "month":
            return 30 * row.price;
          case "week":
            return 7 * row.price;
          case "year":
            return 365 * row.price;
          case "day":
            return 1 * row.price;
          default:
            return 1; // Default value if "period" is not valid
        }
      },
      sortable: true,
    },

    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <>
    //       <Link to={`/freeTrail/${row.id}`} className="menu-link">
    //         <i className="menu-icon tf-icons bx bx-edit"></i>
    //       </Link>

    //       <div className="form-check form-switch">
    //         <input
    //           className="form-check-input"
    //           type="checkbox"
    //           id={`toggleSwitch_${row.id}`}
    //           defaultChecked={!!row.active}
    //           onChange={() => handleToggle(row.id)}
    //         />
    //         <label className="form-check-label" htmlFor={`toggleSwitch_${row.id}`}>
    //         </label>
    //       </div>
    //     </>
    //   ),
    //   allowOverflow: true,
    //   button: true,
    //   width: "56px",
    // }
  ];

  const getFeaturedPlan = async () => {
    try {
      const querySnapshot = await db.collection("featuredPlan").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
      setFeaturedPlanList(data);
    } catch (error) {
      console.error("Error getting featuredPlan list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch featuredPlan list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                {/* button */}
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Featured Plan List</h4>
                  {/* <Link to="/addfeaturedPlan " className="btn btn-primary" type="button">
                    Add FeaturedPlan
                  </Link> */}
                </div>
                <div className="card">
                  <div
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <DataTable
                      columns={columns}
                      data={featuredPlanList}
                      direction="ltr"
                      fixedHeader
                      // fixedHeaderScrollHeight="300px"
                      highlightOnHover
                      pagination
                      persistTableHead
                      responsive
                      subHeaderAlign="right"
                      subHeaderWrap
                      // customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default FeaturedPlan;
