import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import services from "../../services";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";

function CommissionList() {
  const [commissionList, setCommissionList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); // eslint-disable-next-line
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getCommission();
    AOS.init();
  }, []);
  const handleToggle = async (id) => {
    try {
      const commissionRef = db.collection("commission").doc(id);

      const commissionDoc = await commissionRef.get();
      const commissionData = commissionDoc.data();

      const updatedData = {
        ...commissionData,
        isApproved: !commissionData.isApproved,
      };
      await commissionRef.update(updatedData);

      const updatedCommissionList = commissionList.map((commission) => {
        if (commission.id === id) {
          return {
            ...commission,
            isApproved: !commission.isApproved,
          };
        }
        getCommission();
        return commission;
      });
      setCommissionList(updatedCommissionList);
    } catch (error) {
      console.error("Error toggling commission status:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to toggle commission status",
        visibility: "show",
      });
    }
  };
  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Product Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Vendor Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Vendor Contact",
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: "Vendor Email",
      selector: (row) => row.vendor_email,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.user_email,
      sortable: true,
    },
    {
      name: "Commision Fee",
      selector: (row) => row.fee,
      sortable: true,
    },
    // {
    //   name: "image",
    //   cell: (row) => <img src={row.image} alt="Commission Icon" width="50" />,
    //   sortable: true,
    // },

    // {
    //   name: "Status",
    //   selector: (row) => row.isApproved,
    //   sortable: true,
    //   cell: (row) => (
    //     <div>
    //       {row.isApproved ? (
    //         <span className="badge bg-success">Approved</span>
    //       ) : (
    //         <span className="badge bg-danger">Rejected</span>
    //       )}
    //     </div>
    //   ),
    // },

    // {
    //   name: "Manage",
    //   cell: (row) => (
    //     <>
    //       {/* <Link to={`/commissionDetail/${row.id}`} className="menu-link">
    //         <i className="menu-icon tf-icons bx bx-edit"></i>
    //       </Link> */}

    //       <div className="form-check form-switch">
    //         <input
    //           className="form-check-input"
    //           type="checkbox"
    //           id={`toggleSwitch_${row.id}`}
    //           defaultChecked={!!row.isApproved}
    //           onChange={() => handleToggle(row.id)}
    //         />
    //         <label className="form-check-label" htmlFor={`toggleSwitch_${row.id}`}>
    //         </label>
    //       </div>
    //     </>
    //   ),
    //   allowOverflow: true,
    //   button: true,
    //   width: "56px",
    // }
  ];

  const getCommission = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("commission").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
      setCommissionList(data);
    } catch (error) {
      console.error("Error getting commission list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch commission list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                {/* button */}
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Commission List</h4>
                  {/* <Link to="/addcommission " className="btn btn-primary" type="button">
                    Add Commission
                  </Link> */}
                </div>
                <div className="card">
                  <div
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <div className="d-flex justify-content-end">
                      <div class="form-outline mt-4 me-4">
                        {/* <label class="form-label" for="form1">Search</label> */}
                        <input
                          type="search"
                          id="form1"
                          class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="">
                      <small style={{ marginLeft: "9px" }}>commission</small>
                    </div>
                    <hr />
                  </div>
                  <div className="card-body">
                    {isLoaded ? (
                      <Spinner />
                    ) : (
                      <DataTable
                        columns={columns}
                        data={commissionList.filter((row) =>
                          row.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )}
                        direction="ltr"
                        fixedHeader
                        highlightOnHover
                        pagination
                        persistTableHead
                        responsive
                        subHeaderAlign="left"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default CommissionList;
