
import './App.css';
import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/auth/login";
import Profile from "./views/auth/profile"

import CategoryList from "./views/Category/categoryList"
import CategoryDetail from "./views/Category/categoryDetail"
import SubCategoryList from './views/SubCategory/subCategoryList';
import AddSubCategory from './views/SubCategory/addSubCategory';
import SubCategoryDetail from "./views/SubCategory/subCategoryDetail"
import CityList from './views/City/cityList';

import EditCity from './views/City/editCity';
import VendorBookingList from './views/vendorBooking/vendorBookingList';
import VendorProductList from "./views/Products/vendorProduct";
import SubscriptionPlan from "./views/RevenueManagement/subscriptionPlan";
import CommissionList from "./views/Commission/commissionList";
import Complaint from "./views/Contact/complaintList";
import Notification from './views/Contact/notification';
import AddMeals from "./views/restaurant/addMeals"
import MealsList from "./views/restaurant/mealsList"
import AddLandscape from "./views/Landscape/addLandscape"
import LandscapeList from "./views/Landscape/lanscapeList"
import MyComponent from "./views/Table/dataTable"
import User from "./views/User/user"

import UserList from './views/Users/userList';
import FeaturedPlan from './views/RevenueManagement/featuredPlan';
import FreeTrial from './views/RevenueManagement/freeTrial';

import Homesliderlist from './views/Category/Homeslider/Homesliderlist';
import Producdetils from './views/Products/Producdetils';
window.React = React
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route >
          <Route index element={<Login />} />
          <Route path="profile" element={<Profile />} />
      
          <Route path='categoryList' element={<CategoryList />} />
          <Route path="categoryDetail/:id" element={<CategoryDetail />} />
          <Route path="subCategoryList" element={<SubCategoryList />} />
          <Route path="addSubCategory" element={<AddSubCategory />} />
          <Route path="subCategoryDetail/:id" element={<SubCategoryDetail />} />
           <Route path="productdetails/:id"  element={<Producdetils/>}/>
          <Route path="product_booking_list" element={<VendorBookingList />} />
          <Route path='cityList' element={<CityList />} />
        
          <Route path='cityDetail/:id' element={<EditCity />} />
          <Route path="userlist" element={<UserList/>} />
          <Route path="vendorProductList" element={<VendorProductList/>} />
          <Route path="subscriptionPlan" element={<SubscriptionPlan/>} />
          <Route path="featuredPlan" element={<FeaturedPlan/>} />
          <Route path='freeTrial' element={<FreeTrial/>} />
          <Route path="commissionList" element={<CommissionList/>} />
          <Route path="complaintList" element={<Complaint/>} />
          <Route path='notification' element={<Notification/>} />
          <Route path="meals" element={<AddMeals/>} />
          <Route path="mealsList" element={<MealsList />} />
          <Route path="addLandscape" element={<AddLandscape />} />  
          <Route path="landscapeList" element={<LandscapeList />} />
          <Route path="mycomponent" element={<MyComponent />} />
        
        
          {/* <Route path="price" element={<Price />} /> */}
          {/* <Route path="test" element={<Test />} /> */}
         
          <Route path='/HomeSliderlist' element={<Homesliderlist/>}/>
          <Route path="user" element={<User />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
