import React, { useState } from "react";
// import services from "../../services";
import Toaster from "../../components/toaster";
import locales from "../../locales/en.json";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import loginSideImage from "../../../src/images/login_bg_img (1).png";
import "../auth/login.css";

function Login() {
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [passwordType, setPasswordType] = useState("passwordf");

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  // eslint-disable-next-line
  const handleLogin = async () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");
    if (email === "") {
      setEmailError("Enter a valid email address");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Enter a valid email address");
      isValid = false;
    }
    if (password === "") {
      setPasswordError("Please enter password"); // eslint-disable-next-line
      isValid = false;
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((value) => {
          console.log(value, "AKSFLDJASFJSLKAFJ");
          navigate("/categoryList");
        })
        .catch((error) => {
          setToaster({
            type: "danger",
            title: "Error Occured",
            text: "Invalid email or password",
            visiblity: "show",
          });
          setTimeout(() => {
            setToaster({
              visiblity: "hide",
            });
          }, 700);
          navigate("/");
        });
    }
  };
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <img
              src={loginSideImage}
              alt="image not found"
              className="handleLoginImg"
            ></img>
          </div>
          <div className="col-md-6">
            <div className="container-xxl">
              <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
              />
              {/* {!isLoaded && <Spinner />} */}
              <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                  <div className="card">
                    <div className="card-body">
                      <div className="app-brand justify-content-center">
                        <a href="index.html" className="app-brand-link gap-2">
                          <span className="app-brand-logo demo"></span>

                          <span className="app-brand-text demo text-body fw-bolder">
                            Rentors
                            <hr
                              style={{
                                backgroundColor: "#f16509",
                                height: "1px",
                              }}
                            />
                          </span>
                        </a>
                      </div>
                      <h4 className="mb-2">Welcome to Rentors</h4>
                      <p className="mb-4">
                        {locales.Pleasesignintoyouraccount}
                      </p>

                      <div className="mb-3">
                        <label className="form-label">Email or Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email-username"
                          placeholder="Enter your email or username"
                          onChange={(e) => setemail(e.target.value)}
                          value={email}
                          onKeyDown={(e) => {
                            e.key === "Enter"
                              ? handleLogin()
                              : setemail(e.target.value);
                          }}
                        />
                        {emailError ? (
                          <small style={{ color: "red" }}>{emailError}</small>
                        ) : null}
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                          <label className="form-label">Password</label>
                        </div>
                        <div className="input-group input-group-merge">
                          <input
                            type={passwordType}
                            id="password"
                            onChange={(e) => setpassword(e.target.value)}
                            value={password}
                            className="form-control"
                            name="password"
                            onKeyDown={(e) => {
                              e.key === "Enter"
                                ? handleLogin()
                                : setpassword(e.target.value);
                            }}
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            aria-describedby="password"
                          />
                           <br/>
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={() => {
                              passwordType === "password"
                                ? setPasswordType("text")
                                : setPasswordType("password");
                            }}
                          >
                            <i className="bx bx-hide"></i>
                          </span>
                         
                         
                        </div>
                        {passwordError ? (
                            
                            <small style={{ color: "red" }}>
                              {passwordError}
                            </small>
                          ) : null}
                          <br/>
                        <a href="auth-forgot-password-basic.html">
                          <small>Forgot Password</small>
                        </a>
                      </div>
                      <div className="mb-3">
                        <button
                          onClick={() => handleLogin()}
                          className="btn btn-primary d-grid w-100"
                        >
                          Sign in
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
