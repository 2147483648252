import React from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import { useState } from "react";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import constant from "../../constant";
import AWS from "aws-sdk";
import RatingStars from "react-rating-stars-component";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
const S3_BUCKET = constant.S3_BUCKET;
const REGION = constant.REGION;
export const axiosConstant = {
  GOOGLE_PLACES_API_KEY: "AIzaSyCkQqWwnxEPIrUswczekreqJehDjI1pdlQ",
};

function AddMeals() {
  const [file, setFile] = useState(null);
  const [mealsName, setMealsName] = useState("");
  const [mealsNameError, setMealsNameError] = useState("");
  const [mealsImage, setMealsImage] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [mealsWebsite, setMealsWebsite] = useState("");
  const [mealsComment, setMealsComment] = useState("");
  const [price, setPrice] = useState("$");
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [rating, setRating] = useState("1"); // eslint-disable-next-line
  const [googleAddress, setGoogleAddress] = useState(null); // eslint-disable-next-line
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    AWS.config.update({
      accessKeyId: constant.ACCESS_KEYID,
      secretAccessKey: constant.SECRET_ACCESS_KEY,
    });

    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const fileName = file.name;
    const fileType = file.type;
    const s3Params = {
      Bucket: constant.S3_BUCKET,
      Key: `portal/${fileName}`,
      ContentType: fileType,
      Body: file,
    };
    try {
      myBucket
        .putObject(s3Params)
        .on("httpUploadProgress", (evt) => {
          // setProgress(Math.round((evt.loaded / evt.total) * 100))
          console.log(evt, "progress");
        })
        .send((err) => {
          if (err) console.log(err);
        });
      // await s3.putObject(s3Params).promise();
      // alert("File uploaded successfully.");
      // setFile(null);
      setMealsImage(
        `https://maypi-media.s3.eu-central-1.amazonaws.com/portal/${file?.name}`
      );
    } catch (error) {
      console.error(error);
      // alert("Failed to upload file.");
    }
  };

  const popolateAddress = async (address) => {
    if (address) {
      await setGoogleAddress(address);
      let geocode = await geocodeByPlaceId(address.value.place_id);
      console.log(address.value.place_id, "hhhhhhhhhhhhhhh");
      let googleAddress = {
        latitude: JSON.stringify(geocode[0].geometry.location.lat()),
        longitude: JSON.stringify(geocode[0].geometry.location.lng()),
      };
      // get lat and long
      localStorage.setItem("latitude", geocode[0].geometry.location.lat());
      localStorage.setItem("longitude", geocode[0].geometry.location.lng());
      localStorage.setItem(
        "searched_google_object",
        console.log(address.label, "jjjjjjjjjjjj")
      );
      setLatitude(googleAddress.latitude);
      setLongitude(googleAddress.longitude);
      setCity(address.label);
    }
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating.toString());
  };
  const handleChange = (event) => {
    setPrice(event.target.value);
  };
  const OnSubmit = () => {
    console.log("KKKKKKKKKKKKKK");
    let isValid = true;

    if (mealsName === "") {
      setMealsNameError("required");
      isValid = false;
    }

    if (isValid) {
      console.log(city);
      try {
        db.collection("Meals")
          .add({
            id: Math.random().toString(),
            title: mealsName,
            images: [
              `https://maypi-media.s3.eu-central-1.amazonaws.com/portal/${file?.name}`,
            ],
            city: city,
            time: new Date(),
            latitude: latitude,
            longitude: longitude,
            price: price,
            rating: rating,
            website: mealsWebsite,
            check: true,
            comment: [mealsComment],
            created_by: "Admin",
            group_id: "",
            members: [],
          })
          .then((item) => {
            setToaster({
              type: "success",
              title: "Successfull",
              text: "meals created successfully",
              visiblity: "show",
            });

            setMealsComment("");
            setMealsImage("");
            setMealsName("");
            setMealsWebsite("");
            setCity("");
            setRating("");
            setPrice("");
            setSelectedDate("");
            window.location.reload(false);

            setTimeout(() => {
              setToaster({
                visiblity: "hide",
              });
            }, 1500);
          });
      } catch (err) {
        console.log(err, file?.name);
        setToaster({
          type: "data failed",
          title: "Error Occured",
          text: "unsuccessful ",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
      }
    }
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row my-md-auto align-items-center">
                  <div className="col-lg-3">
                    <h4 className="fw-bold pt-3">Add Meals</h4>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row align-items-end">
                          <div className="col-md-4 mt-4">
                            <label className="form-label">Meals Name</label>
                            <input
                              type="text"
                              pattern="[0-9]*"
                              inputMode="numeric"
                              className="form-control"
                              value={mealsName}
                              onChange={(e) => {
                                if (e.target.value !== "") {
                                  setMealsNameError("");
                                }
                                setMealsName(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                e.key === "Enter"
                                  ? OnSubmit()
                                  : setMealsName(e.target.value);
                              }}
                            />
                            {mealsNameError ? (
                              <span className="position-absolute alert-danger mt-2">
                                {mealsNameError}
                              </span>
                            ) : null}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label">Meals Image</label>
                            <input
                              type="file"
                              onChange={handleFileChange}
                              accept="image/gif, image/jpeg, image/png"
                            />
                            <button onClick={handleUpload}>Upload</button>
                            {/* {mealsImageError ? (
                              <span className="position-absolute alert-danger mt-2">
                                {mealsImageError}
                              </span>
                            ) : null} */}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label className="form-label"> Website</label>
                            <input
                              type="url"
                              name="url"
                              className="form-control"
                              value={mealsWebsite}
                              placeholder="http://www.example.com"
                              onChange={(e) => {
                                setMealsWebsite(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                e.key === "Enter"
                                  ? OnSubmit()
                                  : setMealsWebsite(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-md-4 mt-4">
                            <label className="form-label">City</label>
                            <GooglePlacesAutocomplete
                              apiKey={axiosConstant.GOOGLE_PLACES_API_KEY}
                              selectProps={{
                                isDisabled: false,
                                className: "search-input",
                                placeholder: "Address",
                                isClearable: true,
                                onChange: async (val) => {
                                  popolateAddress(val);
                                },
                              }}
                              // value={googleAddress}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label">Price</label>

                            <select
                              className="col-md-12 p-2"
                              value={price}
                              onChange={handleChange}
                              // defaultValue="$"
                            >
                              {/* <option selected>Open this select menu</option> */}
                              <option value="$">Cheaper</option>

                              <option value="$$">Normal</option>

                              <option value="$$$">Expensive</option>
                            </select>

                            {/* {priceError ? (
                              <span className="position-absolute alert-danger mt-2">
                                {priceError}
                              </span>
                            ) : null} */}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label className="form-label"> Commment</label>
                            <input
                              type="text"
                              className="form-control"
                              value={mealsComment}
                              onChange={(e) => {
                                setMealsComment(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                e.key === "Enter"
                                  ? OnSubmit()
                                  : setMealsComment(e.target.value);
                              }}
                            />
                          </div>
                          {/* <div className="col-md-4 mt-4">
                            <label className="form-label">
                              Select a time:{" "}
                            </label> */}
                          {/* <input type="datetime-local" name="timestamp"></input> */}
                          {/* <input
                              type="datetime-local"
                              className="form-control"
                              id="date-input"
                              value={selectedDate}
                              onChange={handleDateChange}
                            /> */}
                          {/* </div> */}
                          <div className="col-md-4 mt-4">
                            <label className="form-label"> Rating</label>
                            <RatingStars
                              count={5}
                              size={24}
                              edit={true}
                              value={rating}
                              onChange={handleRatingChange}
                            />
                          </div>

                          <div className="col-md-4 mt-4">
                            <button
                              type=""
                              className="btn btn-primary me-2"
                              onClick={OnSubmit}
                              disabled={!latitude || !longitude || !mealsImage}
                            >
                              Save Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddMeals;
