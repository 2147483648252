import React from 'react'

function Spinner(props) {
    return (
        <div className='spinnerWrapper'>

            <div className="spinner-border  text-primary" role="status">
                <span className="visually-hidden">{props.text ? props.text : " Loading..."}</span>
            </div>
        </div >
    )
}

export default Spinner;