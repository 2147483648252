import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Axios from 'axios'
import reportWebVitals from './reportWebVitals';
// import initialiseInterceptor from "./api/interceptor"

<link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
// initialiseInterceptor()
const root = ReactDOM.createRoot(document.getElementById('root'));
<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
