import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import moment from 'moment';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Modal, Button } from "react-bootstrap";

function UserList() {
  const [userList, setUserList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getUser();
    AOS.init();
  }, []);

  const handleToggle = async () => {
    setShowModal(true);
  };

  const handleConfirmation = async () => {
    if (selectedUserId) {
      
      try {
        const userRef = db.collection("users").doc(selectedUserId);
        const snapshot = await userRef.get();
        if (snapshot.exists) {
          const user = snapshot.data();
       
          const updatedData = {
            isVerified: user.isVerified ? 0 : 1,
          };
          await userRef.update(updatedData);
          const updatedUserList = userList.map((user) => {
            if (user.id === selectedUserId) {
              return {
                ...user,
                isVerified: user.isVerified ? 0 : 1,
              };
            }
            return user;
          });
          setUserList(updatedUserList);
        }
      } catch (error) {
        console.error("Error toggling user status:", error);
        setToaster({
          type: "error",
          title: "Error Occurred",
          text: "Failed to toggle user status",
          visibility: "show",
        });
      }
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleToggleSwitch = (id, isVerified) => {
    setSelectedUserId(id);
    handleToggle();
  };
  
  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      
    
      sortable: true,
    },
      
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "Mobile No.",
      selector: (row) => row.number,
      sortable: true,
    },
    {
      name: "Verification Status",
      selector: (row) => row.isVerified,
      sortable: true,
      cell: (row) => (
        <div>
          {row.isVerified ? (
            <span className="badge bg-success">Verified</span>
          ) : (
            <span className="badge bg-danger">Pending</span>
          )}
        </div>
      ),
    },
    {
      name: "Profile Status",
      selector: (row) => row.isVerified,
      sortable: true,
      cell: (row) => (
        <div>
          {row.isVerified ? (
            <span className="badge bg-success">Complete</span>
          ) : (
            <span className="badge bg-warning">Incomplete</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`toggleSwitch_${row.id}`}
              checked={!!row.isVerified}
              onChange={() => handleToggleSwitch(row.id, row.isVerified)}
            />
            <label className="form-check-label" htmlFor={`toggleSwitch_${row.id}`}>
            </label>
          </div>
        </>
      ),    
      allowOverflow: true,
      button: true,
      width: "56px",
    }
  ];

  const getUser = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection('users').get();
      const data = querySnapshot.docs.map((doc) => {
        const userData = doc.data();
        userData.id = doc.id;
        return userData;
      });
      console.log("huoiuuuuuuuuuuuuuuuuuuuuuuuuu",data)
      setUserList(data);
    } catch (error) {
      console.error('Error getting user list:', error);
      setToaster({
        type: 'error',
        title: 'Error Occurred',
        text: 'Failed to fetch user list',
        visibility: 'show',
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
console.log("userlist",userList)


  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">User List</h4>
                </div>
                <div className="card">
                  <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className="d-flex justify-content-end">

                      <div class="form-outline mt-4 me-4">
                        {/* <label class="form-label" for="form1">Search</label> */}
                        <input type="search" id="form1" class="form-control"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearch} />

                      </div>
                    </div>
                    <div className="m-2 p-lg-0">

                      <small>users</small>
                    </div>
                    <hr />
                  </div>
                  <div data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <DataTable
                      columns={columns}
                      data={userList.filter((row) =>
                        row.firstName && row.firstName.toLowerCase().includes(searchText.toLowerCase())
                      )}
                      direction="ltr"
                      fixedHeader
                      highlightOnHover
                      pagination
                      persistTableHead
                      responsive
                      subHeaderAlign="left"
                    />
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header >
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default UserList;
