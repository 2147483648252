import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import { useState, useEffect } from "react";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import AOS from "aos";
import "aos/dist/aos.css";
import firebase from "firebase/compat/app";
import { Button, message } from "antd";
import "../../views/Products/vendortbale.css";

import {
  MinusCircleFilled,
  PlusCircleTwoTone,
  PlusCircleFilled,
  PlusCircleOutlined
} from "@ant-design/icons";
export const axiosConstant = {
  GOOGLE_PLACES_API_KEY: "AIzaSyCkQqWwnxEPIrUswczekreqJehDjI1pdlQ",
};

function SubCategoryDetail() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryTitleError, setCategoryTitleError] = useState("");
  const [categorySliderImage, setCategorySliderImage] = useState();
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [inputList, setInputList] = useState([{ label: "" }]);
  const [url, setUrl] = useState("");
  const [updateImage, setUpdateImage] = useState(true);
  const [functioncall, setFuntioncall] = useState(true);
  
  const [chekfield, SetChekfield] = useState("");
  const [privesimage,setpriveusimage]=useState()

  useEffect(() => {
    const fetchSubCategoryData = async () => {
      try {
        const subCategoryRef = db.collection("subCategory").doc(id);
        const subCategoryDoc = await subCategoryRef.get();

        if (subCategoryDoc.exists) {
          const subCategoryData = subCategoryDoc.data();
          const a1=subCategoryData.category_id
          setCategory(a1)
          
          setpriveusimage(subCategoryData.image)
          setCategoryTitle(subCategoryData.name);
          setInputList(subCategoryData.form_field);
          SetChekfield(subCategoryData.form.field);
         
          // Set the form_field value here
          
         ;
          setIsActive(subCategoryData.active);
        }
       
        AOS.init();
        AOS.refresh();
      } catch (error) {
        console.log(error);
      }
    };

    fetchSubCategoryData();
  }, [id]);



  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      setUpdateImage(false);
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(`ListingImages/${file.name}`)
        .put(file); // eslint-disable-next-line no-restricted-globals
      // let conf = confirm("Upload")
      // console.log(conf, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.log("Error uploading file:", error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              setUrl(downloadURL);
              console.log(
                "File uploaded successfully. Download URL:",
                downloadURL
              );
            })
            .catch((error) => {
              console.log("Error getting download URL:", error);
            });
        }
      );
    }

    message.success("  Image Upload Successfully ");
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setFuntioncall(false);
  };
  const handleAddClick = () => {
  
    if([...inputList].length!==0){
      setInputList([...inputList, { label: "" }]);
setFuntioncall(false)
    }

    else{
      setFuntioncall(false)
      console.log('ppppppppppppppppppplllllllllp')
    }
  };
  // const handleIconChange = (e) => {

  //   setFile(e.target.files[0]);
  //   console.log(e.target.files[0], "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
  // };

  const onSubmit = async () => {
    let isValid = true;

    if (categoryTitle === "") {
      setCategoryTitleError("required");
      isValid = false;
    }
    // const iconURL = [URL.createObjectURL(file)];

    if (isValid) {
      try {
        const subcategoryRef = db.collection("subCategory").doc(id);
        if (url) {
          await subcategoryRef
            .update({
              id: id,
              name: categoryTitle,
              form_field: inputList,
              image: url,
              active: isActive,
              category_id: category,
              // id: category,
              products: [],
            })
            .then(() => {
              setToaster({
                type: "success",
                title: "Successfull",
                text: "category created successfully",
                visiblity: "show",
              });

              // setCategorySliderImage("");
              setCategoryTitle("");
              // window.location.reload(false)

              setTimeout(() => {
                setToaster({
                  visiblity: "hide",
                });
              }, 1500);
            });
          navigate("/subCategoryList");
        } else {
          await subcategoryRef
            .update({
              id: id,
              name: categoryTitle,
              form_field: inputList,
              image: privesimage,
              active: isActive,
              category_id: category,
              // id: category,
              products: [],
            })
            .then(() => {
              setToaster({
                type: "success",
                title: "Successfull",
                text: "category created successfully",
                visiblity: "show",
              });

              // setCategorySliderImage("");
              setCategoryTitle("");
              // window.location.reload(false)

              setTimeout(() => {
                setToaster({
                  visiblity: "hide",
                });
              }, 1500);
            });
          navigate("/subCategoryList");
        }
      } catch (err) {
        console.log(err, file?.name);
        setToaster({
          type: "data failed",
          title: "Error Occured",
          text: "unsuccessful ",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
      }
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const querySnapshot = await db.collection("category").get();
      const data = querySnapshot.docs.map((doc) => doc.data());
     
      setSelectedCategory(data);
    } catch (error) {
      console.error("Error getting category list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch category list",
        visibility: "show",
      });
    }
  };
  
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          <SideBar />
          {/* {!isLoaded && <Spinner />} */}
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row my-md-auto align-items-center">
                  <div className="col-lg-3">
                    <h4 className="fw-bold pt-3">Edit Sub Category</h4>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="container" data-aos="fade-up">
                          <div className="row align-items-end">
                            <div className="col-md-4 mt-4">
                              <label className="form-label">
                                Sub Category Title
                              </label>
                              <input
                                type="text"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                className="form-control"
                                value={categoryTitle}
                                onChange={(e) => {
                                  if (e.target.value !== "") {
                                    setCategoryTitleError("");
                                  }
                                  if (e.target.value.length <= 1) {
                                    setCategoryTitle(e.target.value.trim());
                                  } else {
                                    setCategoryTitle(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  e.key === "Enter"
                                    ? onSubmit()
                                    : setCategoryTitle(e.target.value);
                                }}
                              />

                              {categoryTitleError ? (
                                <div>
                                  <span style={{ color: "red" }}>Required</span>
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-4 mt-4">
                              <label className="form-label">
                                Sub Category Icon
                              </label>
                              <input
                                type="file"
                                onChange={handleFileChange}
                                accept="image/gif, image/jpeg, image/png"
                                style={{
                                  border: "1px solid #337ab7",
                                  width: "13rem",
                                }}
                              />
                              {file && (
                                <div>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt="Selected"
                                    height={100}
                                    width={100}
                                  />
                                  <br />
                                  {updateImage && (
                                    <Button
                                      style={{ marginTop: "5px" }}
                                      className="handleBtn"
                                      onClick={() => {
                                        handleUpload();
                                        // setimagerequred(true)
                                      }}
                                    >
                                      Upload
                                    </Button>
                                  )}
                                </div>
                              )}

                              {/* {categorySliderImageError ? (
                              <span className="position-absolute alert-danger mt-2">
                                {categorySliderImageError}
                              </span>
                            ) : null} */}
                            </div>
                            <div className="col-md-4 mt-4">
                              <label className="form-label">
                                Select Category
                              </label>
                              <select
                                className="col-md-12 p-2"
                                value={category || ""}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="">Select a category</option>
                                {selectedCategory &&
                                  selectedCategory.map((cat) => (
                                    <option key={cat.id} value={cat.name}>
                                      {cat.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            {/* <div className="col-md-4 mt-4">
                            <label className="form-label">Sub Category Slider Image</label>
                            <input type="file" onChange={handleIconChange} accept="image/gif, image/jpeg, image/png" />
                          </div> */}

                            <div className="col-md-4 mt-4">
                              <label className="form-label">Status</label>
                              <div className="col-md-8 col-sm-6 col-xs-6">
                                <input
                                  type="radio"
                                  name="cstatus"
                                  value="1"
                                  checked={isActive}
                                  onChange={() => setIsActive(true)}
                                />
                                <label className="radio-label">Active</label>
                                <input
                                  type="radio"
                                  name="cstatus"
                                  value="0"
                                  checked={!isActive}
                                  onChange={() => setIsActive(false)}
                                />
                                <label className="radio-label">Inactive</label>
                              </div>
                            </div>

                            <div className="handlesubcard">
                              <div className="card-body1">
                                <div className="col-md-4 mt-4">
                                  <label className="form-label">
                                    Sub Category Form Fields
                                  </label>
                                  {inputList.map((field, index) => (
                                    <div className="box" key={index}>
                                      <input
                                        name="label"
                                        style={{borderRadius:'5px',border:'2px solid #337ab7'}}
                                        placeholder="  Enter Field Label"
                                        setfieldvalue={field.label}
                                        value={field.label}
                                        onChange={(e) => {
                                         
                                          if (e.target.value.trim()) {
                                           
                                            handleInputChange(e, index);

                                            setFuntioncall(true);
                                          }

                                          //                  if(e.target.value.length!==" ")
                                          //                  {
                                          //                   handleInputChange(e, index)

                                          //                     setFuntioncall(true)
                                          //                     handleInputChange(e, index)

                                          //                  }

                                          // if(chekfield){
                                          //   setFuntioncall(true)
                                          // }
                                          else {
                                            handleInputChange(e, index);

                                            setFuntioncall(false);
                                      
                                          }
                                        }}
                                      />
                                      <div style={{marginTop:"4px",marginBottom:"4px"}}>
                                      &nbsp;
                                        {inputList.length !== 1 && (
                                          <span
    //                         style={{    border:" 1px solid #8592a3",
    // height: "30px",
    // width: "28px",
    // paddingLeft: '6px',
    // borderRadius: '50%',
    // marginTop: '5px',
    // backgroundColor: '#8592a3'}}
                                            
                                            onClick={() => {
                                              handleRemoveClick(index);

                                              setFuntioncall(true);
                                            }}
                                          >
                                            <MinusCircleFilled style={{ fontSize: '25px',  color: '#8592a3'  }}/>
                                          </span>
                                        )}
                                        &nbsp;
                                        {inputList.length - 1 === index && (
                                          <span
    //                                       style={{    border:" 1px solid #337ab7",
    // height: "30px",
    // width: "28px",
    // paddingLeft: '6px',
    // borderRadius: '50%',
    // marginTop: '5px',
    // backgroundColor: '#337ab7'}}
                                            onClick={() => {
                                           
                                              if (functioncall) {
                                                handleAddClick();
                                             
                                                setFuntioncall(false);
                                              } else {
                                                message.success(
                                                  "Label Fields Required"
                                                );
                                              }
                                            }}
                                          >
                                          <PlusCircleOutlined  style={{ fontSize: '25px',color  :' #337ab7'   }} />
                                            {/* <PlusCircleTwoTone  style={{ fontSize: '25px',color:'#337ab7'   }} /> */}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                           
                            <div className="col-md-4">
                              <button
                                type=""
                                className="btn me-2 handleBtn"
                                onClick={() => {
                                  if(functioncall){
                                    onSubmit();
                                  }
                                else{
                                  message.success('Label Field Required')
                                }
                                }}
                                // disabled={!latitude || !longitude || !categorySliderImage}
                              >
                                Save Data
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubCategoryDetail;
