import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import DataTable from "react-data-table-component";
import Toaster from "../../components/toaster";
import { db } from "../../firebase";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "react-bootstrap";
import { forEach } from "lodash";
import "../Products/vendortbale.css";
import { Button } from "antd";

function VendorProductList() {
  const [VendorList, setvendorList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toaster, setToaster] = useState({ visibility: "hide" });
  const [showModal, setShowModal] = useState(false);
  const [selectedvendorid, setSelectedvendorid] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [show, setShow] = useState(false);

  useEffect(() => {
    getvendorproduct();

    AOS.init();
  }, []);

  const handleToggle = async () => {
    setShowModal(true);
  };

  //details function

  ///isApproved funtion
  const handleConfirmation = async () => {
    if (selectedvendorid) {
      try {
        const userRef = db.collection("product").doc(selectedvendorid);
        const snapshot = await userRef.get();
        if (snapshot.exists) {
          const user = snapshot.data();

          const updatedData = {
            isApproved: user.isApproved ? 0 : 1,
          };
          await userRef.update(updatedData);
          const updatedUserList = VendorList.map((user) => {
            if (user.id === selectedvendorid) {
              return {
                ...user,
                isApproved: user.isApproved ? 0 : 1,
              };
            }
            return user;
          });
          setvendorList(updatedUserList);
        }
      } catch (error) {
        console.error("Error toggling user status:", error);
        setToaster({
          type: "error",
          title: "Error Occurred",
          text: "Failed to toggle user status",
          visibility: "show",
        });
      }
    }
    setShowModal(false);
  };

  //details data for table in toggle

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleToggleSwitch = (id, isVerified) => {
    setSelectedvendorid(id);
    handleToggle();
  };

  const columns = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.user_id,

      sortable: true,
    },

    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Sub Category",
      selector: (row) => row.subcategory,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },

    {
      name: "Verification Status",
      selector: (row) => row.isApproved,
      sortable: true,
      cell: (row) => (
        <div>
          {row.isApproved ? (
            <span className="badge bg-success">Complete</span>
          ) : (
            <span className="badge bg-warning">Incomplete</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              style={{
                height: "15px",
                width: "px",
                borderBlockColor: "red",
              }}
              type="checkbox"
              id={`toggleSwitch_${row.id}`}
              checked={!!row.isVerified}
              onChange={() => handleToggleSwitch(row.id, row.isApproved)}
            />

            <Link to={`/productdetails/${row.id}`} className="menu-link">
              <Button size="small" className="handleBtn">
                Details
              </Button>
            </Link>
            {/* <Button variant="primary" onClick={() => {
              setdetailsid(row.id)

              handleShow()
              detailsvendor()

            }}
            >
              Details
            </Button> */}
            <label
              className="form-check-label"
              htmlFor={`toggleSwitch_${row.id}`}
            ></label>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "150px",
    },
  ];
  //get vendor product
  const getvendorproduct = async () => {
    setIsLoaded(true);
    try {
      const querySnapshot = await db.collection("product").get();
      const data = querySnapshot.docs.map((doc) => {
        const userData = doc.data();
        userData.id = doc.id;
        return userData;
      });
      console.log("huoiuuuuuuuuuuuuuuuuuuuuuuuuu", data);
      setvendorList(data);
    } catch (error) {
      console.error("Error getting user list:", error);
      setToaster({
        type: "error",
        title: "Error Occurred",
        text: "Failed to fetch user list",
        visibility: "show",
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visibility={toaster.visibility}
          />
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold"> Vendor List</h4>
                  {/* <Link to="/addCity" className="btn btn-primary" type="button">
                    Add City
                  </Link> */}
                </div>

                <div
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <div className="d-flex justify-content-end">
                    <div class="form-outline mt-4 me-4">
                      {/* <label class="form-label" for="form1">Search</label> */}
                      <input
                        type="search"
                        id="form1"
                        class="form-control"
                        placeholder="Search"
                        value={searchText}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="m-2 p-lg-0">
                    <small>Producrt</small>
                  </div>
                  <hr />

                  <DataTable
                    columns={columns}
                    data={VendorList.filter(
                      (row) =>
                        row.name &&
                        row.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                    )}
                    direction="ltr"
                    fixedHeader
                    highlightOnHover
                    pagination
                    persistTableHead
                    responsive
                    subHeaderAlign="left"
                  />
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to isApproved status?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal details */}
    </div>
  );
}

export default VendorProductList;
